import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-view-reports',
  templateUrl: './view-reports.component.html',
  styleUrls: ['./view-reports.component.scss']
})
export class ViewReportsComponent {
  @Input() reports: any | null = null;
  @Output() closeDialog = new EventEmitter<void>();

  constructor() {
  }

  formatTimestamp(timestamp: number): string {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthsOfYear = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const date = new Date(timestamp);

    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = String(date.getDate()).padStart(2, '0');
    const month = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const amPm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${dayOfWeek}, ${day} ${month} ${year}, ${formattedHours}:${minutes}:${seconds} ${amPm}`;
  }

  printSection() {
    // Emit the closeDialog event
    this.closeDialog.emit();

    // Get the HTML content of the section to print
    const printContent = document.querySelector('.container')?.innerHTML;

    if (printContent) {
      // Create a new window for printing
      const printWindow = window.open('', '', 'height=600,width=800');

      if (printWindow) {
        // Write the HTML content into the new window
        printWindow.document.write('<html><head><title>Print</title>');
        printWindow.document.write('<style>\n' +
          '.container {\n' +
          '  background-color: #fff;\n' +
          '  padding: 20px;\n' +
          '\n' +
          '  margin: 0 auto;\n' +
          '  border: 1px solid #ddd;\n' +
          '  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n' +
          '}\n' +
          '\n' +
          '.header {\n' +
          '  display: flex;\n' +
          '  justify-content: space-between;\n' +
          '  align-items: flex-start;\n' +
          '  border-bottom: 1px solid var(--color1);\n' +
          '  padding-bottom: 10px;\n' +
          '  margin-bottom: 20px;\n' +
          '}\n' +
          '\n' +
          '.logo img {\n' +
          '  width: 170px;\n' +
          '}\n' +
          '\n' +
          '.details, .template-info {\n' +
          '  font-size: 14px;\n' +
          '  p{\n' +
          '    padding: 0.2vw 0;\n' +
          '  }\n' +
          '}\n' +
          '\n' +
          'h3.title {\n' +
          '  text-align: left;\n' +
          '  color: rgba(0, 0, 128, 0.8);\n' +
          '  font-size: 21px;\n' +
          '  margin-bottom: 20px;\n' +
          '}\n' +
          '\n' +
          '.info-table {\n' +
          '  width: 100%;\n' +
          '  margin-bottom: 20px;\n' +
          '  border-collapse: collapse;\n' +
          '}\n' +
          '\n' +
          '.info-table td {\n' +
          '  padding: 8px;\n' +
          '  border-bottom: 1px solid #ddd;\n' +
          '  font-size: 14px;\n' +
          '}\n' +
          '\n' +
          '.violation-description {\n' +
          '  font-size: 16px;\n' +
          '  color: #333;\n' +
          '}\n' +
          '\n' +
          '.violation-description h2 {\n' +
          '  color: #333;\n' +
          '  font-size: 20px;\n' +
          '  margin-bottom: 10px;\n' +
          '}\n' +
          '\n' +
          '.violation-description p {\n' +
          '  margin-bottom: 10px;\n' +
          '}\n' +
          '\n' +
          '.violation-description ul {\n' +
          '  list-style-type: disc;\n' +
          '  padding-left: 20px;\n' +
          '}\n' +
          '.btns {\n' +
          '  display:none;\n' +
          '}\n' +
          '</style>');
        printWindow.document.write('</head><body >');
        printWindow.document.write(printContent);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
      }
    }
  }


}
