<div class="users-container" [class.arabic]="isArabic">
  <h1 class="t5">{{ 'SIDEBAR.USERS' | translate }}</h1>

  <div class="controls">
    <div class="left-controls">
      <select class="show-select" [(ngModel)]="tableHeadersize" (change)="onSizeChange($event)">
        <option [value]="5">5</option>
        <option [value]="10">10</option>
        <option [value]="20">20</option>
      </select>
      <select class="show-select" [(ngModel)]="selectedRole">
        <option disabled>{{ 'SELECT_A_ROLE' | translate }}</option>
        <option *ngFor="let role of roles" [value]="role">{{ role | translate }}</option>
      </select>
      <select class="show-select" [(ngModel)]="verified">
        <option value="true">{{ 'VERIFIED' | translate }}</option>
        <option value="false">{{ 'UNVERIFIED' | translate }}</option>
      </select>
      <input type="text" [placeholder]="'SEARCH_PLACEHOLDER' | translate" class="user-search" [(ngModel)]="name">
      <button class="apply-btn" [class.arabic]="isArabic" (click)="getUsers(0)">{{ 'APPLY' | translate }}</button>
    </div>
  </div>

  <table class="users-table">
    <thead>
      <tr>
        <th [class.arabic]="isArabic" *ngFor="let header of tableHeaders">
          {{ header.key | translate }}
          <!-- <span *ngIf="header.sortable" class="sort-icon">▼</span> -->
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of paginatedUsers.users">
        <td><img [src]="user.profileimage?ROOT+user.profileimage.url:'assets/images/waynek-1.png'"
            [alt]="'PROFILE_IMAGE' | translate" class="profile-img"></td>
        <td>{{ user.name?user.name:'-' }}</td>
        <td>{{ user.timestmp | date:'dd/MM/yyyy' }}</td>
        <td>{{ user.phonenumber?user.phonenumber:'-' }}</td>
        <td>{{ user.groupsnumber }} <span class="eye-icon" (click)="openViewDialog('VIEW_GROUPS_USER', user)"><i
              class="fa-solid fa-eye color1"></i></span></td>
        <td>{{ user.city?user.city:'-' }}</td>
        <td>{{ user.country?user.country:'-' }}</td>
        <td>
          <span class="action-icon t1 color1" [title]="'VIEW' | translate" (click)="openViewDialog('VIEW_USER', user)">
            <i class="fa-solid fa-eye"></i>
          </span>
          <span class="action-icon t1 color9" *ngIf="hasAccess()" [title]="'Edit' | translate"
            (click)="openViewDialog('UPDATE_USER', user)">
            <i class="fa-solid fa-user-pen"></i>
          </span>
          <span class="action-icon t1 color6" *ngIf="hasAccess()" (click)="Activate(user?.id)"
            [title]="'Activation' | translate">
            <i [class]="user.active?'fa-solid fa-user-xmark':'fa-solid fa-user-check'"></i>
          </span>
        </td>
      </tr>
      <tr *ngIf="paginatedUsers.users.length === 0">
        <td colspan="7" class="text-center">{{ 'No Data Found' | translate }}</td>
      </tr>
    </tbody>
  </table>



  <!--[rowsPerPageOptions]="[5, 10, 20]" -->
  <div class="paginator">
    <p-paginator *ngIf="paginatedUsers.users.length > 0" [rows]="paginatedUsers.size"
      [totalRecords]="paginatedUsers.nb_users" (onPageChange)="onPageChange($event)"></p-paginator>
  </div>
</div>
