import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MessageService } from "primeng/api";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { FbNotification } from '../../../models/msg';
import { MessagePayload } from 'firebase/messaging';
import { Messaging } from "@angular/fire/messaging";
import { SharedService } from '../shared/shared.service';
@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  // messaging!: Messaging;
  // currentMessage = new BehaviorSubject<MessagePayload | FbNotification>({} as FbNotification);
  // currentToken = new BehaviorSubject<string | null>(null);
  // token = this.currentToken.asObservable();
  // message = this.currentMessage.asObservable();
  // user: any
  //
  // constructor(
  //   private angularFireMessaging: AngularFireMessaging,
  //   private msgService: MessageService,
  //   private shared: SharedService
  // ) {
  //   this.shared.currentUser.subscribe((res) => {
  //     if (res) {
  //       this.user = res
  //     }
  //   })
  // }
  //
  //
  // requestPermission() {
  //   this.angularFireMessaging.requestPermission
  //     .subscribe(
  //       {
  //         next: (permission) => {
  //         }, error:
  //           (error) => {
  //             console.error('Error requesting permission:', error);
  //           }
  //       }
  //     );
  // }
  //
  //
  // requestToken() {
  //   this.angularFireMessaging.requestToken.subscribe({
  //     next: (res) => {
  //       this.currentToken.next(res)
  //     }
  //   })
  // }
  //
  //
  // get Token() {
  //   return this.currentToken.value
  // }
  //
  //
  //
  // receivedMessages: any[] = []
  // receiveMessage() {
  //   this.angularFireMessaging.messages.subscribe(
  //     (payload:any) => {
  //       // if (payload.data['sender'] != this.user.id.toString()) {
  //         if (!this.receivedMessages.find((id) => payload.data['id'] == id)) {
  //           // this.playSound()
  //           this.msgService.add({
  //             severity: 'success',
  //             summary: payload.notification?.title,
  //             detail: payload.notification?.body,
  //             data: payload.data,
  //           });
  //           this.receivedMessages.push(payload.data['id'])
  //           console.log("Msg Received",payload.data)
  //           this.shared.sendData({action  :'NEW_NOTIFICATION',data:payload.data})
  //         }
  //       // }
  //
  //     })
  // }
}
