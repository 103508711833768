import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent {
  @Input() user: any | null = null;
  @Output() closeDialog = new EventEmitter<void>();


  constructor() {
  setTimeout(()=>{
    this.onCancel();
  },8000)
  }

  onCancel() {
    this.closeDialog.emit();
  }

}
