<div class="controls">
  <div class="left-controls">
    <select class="show-select" [(ngModel)]="tableHeadersize" (change)="onSizeChange($event)">
      <option [value]="5">5</option>
      <option [value]="10">10</option>
      <option [value]="20">20</option>
    </select>
    <input type="text" [placeholder]="'SEARCH_PLACEHOLDER' | translate" class="user-search" [(ngModel)]="CheckIN">
    <button class="apply-btn" [class.arabic]="isArabic" (click)="GetAllCheckINs(0)">{{ 'APPLY' | translate }}</button>
  </div>
  <div class="right-controls">
    <select class="export-btn" (change)="onExportChange($event)">
      <option value="" disabled selected>{{ 'EXPORT' | translate }}</option>
      <option value="pdf">PDF</option>
      <option value="csv">CSV</option>
    </select>
  </div>
</div>

<table class="users-table">
  <thead>
  <tr>
    <th [class.arabic]="isArabic" *ngFor="let header of tableHeaders">
      {{ header.key | translate }}
    </th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngIf="paginatedCheckINs.check_ins.length > 0">
    <tr *ngFor="let activity of paginatedCheckINs.check_ins">
      <td>{{ activity.username }}</td>
      <td>{{ activity.timestamp | date }}</td>
      <td>{{ activity.location?activity.location.name:'-' }}</td>
      <td>{{ activity.checkdiameter }}</td>
      <td>{{ activity.groupname }}</td>
      <td>{{ formatTimestampToTime(activity.timestamp) }}</td>
      <td>
        <button class="details-btn"
                (click)="openViewDialog('VIEW_ACTIVITY_DETAILS', activity)">{{ 'DETAILS' | translate }}
        </button>
      </td>
    </tr>
  </ng-container>
  <tr *ngIf="paginatedCheckINs.check_ins.length === 0">
    <td colspan="7" class="text-center">{{ 'No Data Found' | translate }}</td>
  </tr>
  </tbody>
</table>

<div class="paginator">
  <p-paginator *ngIf="paginatedCheckINs.check_ins.length > 0" [rows]="paginatedCheckINs.size"
               [totalRecords]="paginatedCheckINs.nb_check_ins" (onPageChange)="onPageChange($event)"></p-paginator>
</div>
