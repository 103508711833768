import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { Groups } from '../../../models/user';
import { TranslateService } from '@ngx-translate/core';
import { Map, MapStyle, config } from '@maptiler/sdk';
import '@maptiler/sdk/dist/maptiler-sdk.css';
import { Marker } from '@maptiler/sdk';
@Component({
  selector: 'locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  @Input() group: Groups | null = null;
  @Output() closeDialog = new EventEmitter<void>();
  currentLang: string = '';
  map!: Map;
  marker!: Marker;
  initialState:any = { lng: 45.0792, lat: 23.8859, zoom: 6 };
  selectedLocation: any;
  @ViewChild('map') private mapContainer!: ElementRef<HTMLElement>;

  constructor(private translate: TranslateService) {
    this.currentLang = this.translate.currentLang;
  }

  locations: any[] = [];

  ngOnInit() {
    config.apiKey = 'o537OHI8dKS8vdf22tq3';
    if (this.group) {
      this.LoadGroups();
    }
  }

  LoadGroups() {
    this.locations = this.group?.locations || [];
  }

  onLocationClick(location: any) {
    this.initialState.lat = location.latitude;
    this.initialState.lng = location.longitude;
  }

}
