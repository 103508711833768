import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: 'option'
})
export class OptionsDirective {

  constructor(private el: ElementRef, private renderer: Renderer2,private translate: TranslateService) {
    const savedLanguage = translate.currentLang;
    const className = savedLanguage === 'ar' ? 'CM' : 'PM';

    this.renderer.addClass(this.el.nativeElement, className);
  }
}
