import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PrivilegeService } from '../../../services/Api/Prev/privilege.service';
import { ToastService } from "../../../services/others/toast.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'privileges',
  templateUrl: './prev.component.html',
  styleUrls: ['./prev.component.scss']
})
export class PrevComponent {
  @Input() sub: any | null = null;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() UpdatePrev = new EventEmitter<any>();
  user_privileges: any[] = [];

  // Dependency mapping
  private PRIVILEGE_DEPENDENCIES = {
    'USER_WRITE': ['USER_READ'],
    'GROUP_WRITE': ['GROUP_READ'],
    'EVENT_WRITE': ['EVENT_READ'],
    'SUB_ADMIN_WRITE': ['SUB_ADMIN_READ'],
    'GLOBAL_SETTINGS_WRITE': ['GLOBAL_SETTINGS_READ'],
    'PROFILE_WRITE': ['PROFILE_READ'],
    'NOTIFICATION_WRITE': ['NOTIFICATION_READ'],
    'REPORT_WRITE': ['REPORT_READ']
  };

  constructor(
    private service: PrivilegeService,
    private translate: TranslateService,
    private Toaster: ToastService
  ) {
    this.GetAll();
  }

  // Fetch all privileges from the API
  GetAll() {
    this.service.all_prev().then((res) => {
      this.user_privileges = res.map(apiPrivilege => ({
        id: apiPrivilege.id,
        name: apiPrivilege.name,
        checked: this.Check_if_has(apiPrivilege.name),
        isNew: !this.Check_if_has(apiPrivilege.name),
        toRemove: false
      }));
    });
  }

  // Check if the privilege can be checked
  canCheck(privilege: any): boolean {
    const dependencies = this.PRIVILEGE_DEPENDENCIES[privilege.name];
    if (dependencies) {
      return dependencies.every(dep =>
        this.user_privileges.find(p => p.name === dep)?.checked
      );
    }
    return true; // No dependencies
  }

  // Handle privilege click for removal
  onPrivilegeClick(privilege: any) {
    if (privilege.checked && !privilege.isNew) {
      privilege.toRemove = !privilege.toRemove; // Toggle removal state
    }
  }

  // Update privilege state
  updatePrivilegeState(privilege: any) {
    if (privilege.checked) {
      if (!this.canCheck(privilege)) {
        privilege.checked = false; // Revert if dependencies are not met
        this.Toaster.ShowToastError(this.translate.instant("MSG.DEPENDENCY_NOT_MET"), this.translate.instant("MSG.WARNING"));
      }
    }
  }

  // Add new privileges
  async add_prev() {
    if (this.sub) {
      const selectedPrivileges = this.user_privileges
        .filter(priv => priv.checked && priv.isNew)
        .map(priv => priv.id);

      try {
        await this.service.add_prev(this.sub.id, selectedPrivileges);
        this.Done(this.sub);
        this.Toaster.ShowToastSuccess(this.translate.instant("MSG.PREV_ADDED"), this.translate.instant("MSG.SUCCESS"));
      } catch (error) {
        this.Toaster.ShowToastError(this.translate.instant("MSG.ERROR"), this.translate.instant("MSG.WARNING"));
      }
    }
  }

  // Remove selected privileges
  async remove_prev() {
    if (this.sub) {
      const privilegesToRemove = this.sub?.privileges
        .filter(priv => priv.toRemove)
        .map(priv => priv.id);

      if (!privilegesToRemove || privilegesToRemove.length === 0) {
        this.Toaster.ShowToastError(this.translate.instant("MSG.NO_SELECTION"), this.translate.instant("MSG.WARNING"));
        return;
      }

      try {
        await this.service.remove_prev(this.sub.id, privilegesToRemove);
        this.Done(this.sub);
        this.Toaster.ShowToastSuccess(this.translate.instant("MSG.PREV_REMOVED"), this.translate.instant("MSG.SUCCESS"));
      } catch (error) {
        this.Toaster.ShowToastError(this.translate.instant("MSG.ERROR"), this.translate.instant("MSG.WARNING"));
      }
    }
  }

  // Close dialog
  onClose() {
    this.closeDialog.emit();
  }

  // Finalize changes
  Done(data: any) {
    this.UpdatePrev.emit(data);
    this.closeDialog.emit();
  }

  // Check if the user has a specific privilege
  Check_if_has(name: string): boolean {
    return this.sub?.privileges.some(priv => priv.name === name) || false;
  }
}
