<div class="privileges-container">
  <div class="creating-date">
    <span class="creating-date-text">
      <i class="fa-solid fa-user plr-0-5vw"></i>
      <strong>{{sub.email}}</strong>
    </span>
  </div>


  <div class="ptb-1vw">
    <strong>{{'ADD_PRIVILEGES' | translate}}</strong>
  </div>

  <div class="privileges-list">
    <div *ngFor="let privilege of user_privileges" class="privilege-item">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="privilege.checked"
          [ngClass]="{
            'checked': privilege.checked && !privilege.isNew,
            'new': privilege.checked && privilege.isNew
          }"
          [disabled]="(!privilege.isNew && privilege.checked) || !this.canCheck(privilege)"
        (change)="updatePrivilegeState(privilege)"
        >
        {{ privilege.name.replace('_', ' ') | translate }}
      </label>
    </div>
  </div>

  <div class="btns">
    <button class="add-button" (click)="add_prev()">{{ 'ADD_PRIVILEGES' | translate }}</button>

  </div>

  <div class="ptb-2vw">
    <hr>
  </div>

  <div class="ptb-1vw">
    <strong>{{'REMOVE_PRIVILEGES' | translate}}</strong>
  </div>

  <div class="privileges-list">
    <div *ngFor="let privilege of sub?.privileges" class="privilege-item">
      <label [ngClass]="{'to-remove': privilege.toRemove}">
        <input
          type="checkbox"
          [(ngModel)]="privilege.checked"
          (change)="onPrivilegeClick(privilege)"
          [ngClass]="{
            'checked': privilege.checked && !privilege.isNew,
            'new': privilege.checked && privilege.isNew,
            'remove': privilege.toRemove
          }"
        >
        {{ privilege.name.replace('_', ' ') | translate }}
      </label>
    </div>
  </div>
  <div class="btns">
  <button class="remove-button" (click)="remove_prev()">{{ 'REMOVE_PRIVILEGES' | translate }}</button>
  </div>
</div>
