export const environment = {
  production: false,
  apiUrl: 'https://server.waynek.net',
  baseSocket: "https://server.waynek.net/ws",
  cdn: 'https://waynekstorage.nyc3.cdn.digitaloceanspaces.com',
  firebase: {
    apiKey: "AIzaSyCi7KW5F04HuCYB5AUce3rgpmRAUmXCUOc",
    authDomain: "waynek-abc7d.firebaseapp.com",
    projectId: "waynek-abc7d",
    storageBucket: "waynek-abc7d.appspot.com",
    messagingSenderId: "980520176966",
    appId: "1:980520176966:web:a298d985e8798a997ce947",
    measurementId: "G-R9WWDN5BPT"
  }
};
