import {Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {Group, Groups, LoggedUser, Member} from '../../../models/user';
import {environment} from "../../../../environments/environment";
import { Browser, Map, map, tileLayer } from 'leaflet';

@Component({
  selector: 'members',
  templateUrl: './members.component.html',
  styleUrl: './members.component.scss'
})
export class MembersComponent implements OnInit{
  @Input() group: Groups | null = null;
  @Output() closeDialog = new EventEmitter<void>();
  filteredMembers: LoggedUser[] = [];
  searchTerm: string = '';
  members: LoggedUser[] = [];
  ROOT = environment.apiUrl
  @ViewChild('map') private mapContainer!: ElementRef<HTMLElement>;


  constructor() {
  }
  ngOnInit() {
    if (this.group) {
      this.loadMembers();
      this.filteredMembers = this.members;
    }
  }

  loadMembers() {

    this.members = this.group?.members || [];
    this.filteredMembers = this.members;
  }

  onClose() {
    this.closeDialog.emit();
  }

  filterMembers() {
    this.filteredMembers = this.members.filter(member =>
      member.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

}
