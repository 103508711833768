import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const cookieService = inject(CookieService);

  const isLoggedIn = cookieService.get('STATE_DASH_WAYNEK') === 'true';


  if (isLoggedIn) {
    return true;
  } else {
    router.navigate(['/Sign-In']);
    return false;
  }
};
