import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(
    private http: HttpClient,
  ) { }

  getGroups(page: number,size: number,name?:string,archive?:boolean) {
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size)

    if (name) httpParams = httpParams.append('name', name);
    if (archive) httpParams = httpParams.append('archive', archive);

    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/group/all_groups`, { params: httpParams }));
  }


  all_users_group(id: number) {
    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/group/all_user_groups/${id}`, {}));
  }

  suspend_group(group_id: number) {
    return lastValueFrom(this.http.patch<any>(`${environment.apiUrl}/api/v1/group/suspend_group/${group_id}`, {}));
  }

  delete_group(group_id: number) {
    return lastValueFrom(this.http.patch<any>(`${environment.apiUrl}/api/v1/group/delete_group/${group_id}`, {}));
  }


}
