<div class="users-container" [class.arabic]="CurrentLanguage==='ar'">
    <h1 class="t5">{{ 'SIDEBAR.GROUPS' | translate }}</h1>
    
    <div class="fxColumn fxAlignCenterCenter">
      <div class="fxRow fxAlignCenterCenter pb-3vw">
        <button mat-button class="t1 tab-item" (click)="selectedIndex=1"
                [ngClass]="{'tab-item-selected' : selectedIndex==1}" [routerLink]="[]"
                [queryParams]="{page : 'Groups'}"
                queryParamsHandling="merge">{{'SIDEBAR.GROUPS'| translate}}</button>
        <button mat-button class="t1 tab-item" (click)="selectedIndex=2"
                [ngClass]="{'tab-item-selected' : selectedIndex==2}" [routerLink]="[]"
                [queryParams]="{page : 'Check-ins Locations'}"
                queryParamsHandling="merge">{{'CHECK_INS_LOCATIONS'| translate}}</button>
    
      </div>
    
      <div class="w-100" *ngIf="selectedIndex==1">
          <groups></groups>
      </div>
      <div class="w-100" *ngIf="selectedIndex==2">
          <check-ins-locations></check-ins-locations>
      </div>
    
    
    </div>
  </div>



  
  
  
  