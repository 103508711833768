import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastrService: ToastrService) {
    this.setGlobalToastrConfig();
  }

  private setGlobalToastrConfig() {
    this.toastrService.toastrConfig.progressBar = true;
    this.toastrService.toastrConfig.closeButton = true;
    this.toastrService.toastrConfig.timeOut = 3000;
    this.toastrService.toastrConfig.extendedTimeOut = 3000;
    this.toastrService.toastrConfig.positionClass = 'toast-top-right';
    this.toastrService.toastrConfig.toastClass = 'ngx-toastr custom-toast CM';
  }

  ShowToastSuccess(message: string, title: string) {
    this.toastrService.success(message, title);
  }

  ShowToastError(message: string, title: string) {
    this.toastrService.error(message, title);
  }

}
