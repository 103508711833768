import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CheckInsService {

  constructor(
    private http: HttpClient,
  ) { }


  all_checkins(page: number,size: number,name?:string) {
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size)

    if (name) httpParams = httpParams.append('groupname', name);

    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/checkins/all_checkins`, { params: httpParams }));
  }
}
