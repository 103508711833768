import {Component, OnInit, OnDestroy} from '@angular/core';
import {DialogService} from '../../services/others/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {LoggedUser, User} from '../../models/user';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {UserService} from '../../services/Api/user/user.service';
import {PaginatedUsers} from '../../models/paginators';
import {NgxSpinnerService} from 'ngx-spinner';
import {GroupsService} from "../../services/Api/Groups/groups.service";
import {environment} from "../../../environments/environment";
import {AuthService} from '../../services/Api/Auth/auth.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit, OnDestroy {
  isArabic = false;
  ROOT = environment.cdn
  message: string | null = null;
  paginatedUsers: PaginatedUsers = {size: 5, nb_users: 0, page: -1, users: [], pages: 1}
  tableHeadersize: number = 5;
  roles: string[] = ['ROLE_ADMIN', 'ROLE_SUB_ADMIN', 'ROLE_USER'];
  tableHeaders = [
    {key: 'PROFILE', sortable: false},
    {key: 'NAME', sortable: true},
    {key: 'REGISTER_DATE', sortable: true},
    {key: 'PHONE_NUMBER', sortable: true},
    {key: 'NUMBER_OF_GROUPS', sortable: true},
    {key: 'CITY', sortable: true},
    {key: 'COUNTRY', sortable: true},
    {key: 'ACTIONS', sortable: false}
  ];
  users: LoggedUser[] = [];
  LoggedUser: any
  selectedRole: 'ROLE_ADMIN' | 'ROLE_SUB_ADMIN' | 'ROLE_USER' = 'ROLE_USER';
  verified: boolean = true;
  name: string = ""

  private deleteUserSubscription: Subscription;
  private updateUserSubscription: Subscription;

  constructor(private authService: AuthService, private dialogService: DialogService, private translate: TranslateService, private router: Router, private userService: UserService, private spinner: NgxSpinnerService, private group_service: GroupsService) {
    this.authService.CurrentUser().then((res) => {
      this.LoggedUser = res;
    });
    this.checkIsArabic();
    this.deleteUserSubscription = this.dialogService.deleteUser$.subscribe(user => {
      this.handleDeleteUser();
    });
    this.updateUserSubscription = this.dialogService.updateUser$.subscribe(user => {
      this.handleUpdateUser();
    });
    this.getUsers(0);
  }

  ngOnInit() {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.message = navigation.extras.state['message'];


      // Optionally, clear the message after displaying
      setTimeout(() => this.message = null, 5000);
    }
  }

  ngOnDestroy() {
    if (this.deleteUserSubscription) {
      this.deleteUserSubscription.unsubscribe();
    }
  }

  handleDeleteUser() {
    this.getUsers(this.paginatedUsers.page);
  }


  handleUpdateUser() {
    this.getUsers(this.paginatedUsers.page);
  }


  openViewDialog(title: string, user: User) {
    this.dialogService.openDialog(title, user);
  }

  checkIsArabic() {
    this.isArabic = this.translate.currentLang === 'ar';
  }

  getUsers(page: number) {
    this.spinner.show()
    const size = this.paginatedUsers.size || 5;
    this.userService.getUsers(page, size, this.selectedRole, this.verified, this.name).then((res) => {
      this.spinner.hide()
      this.paginatedUsers.nb_users = res.totalItems
      this.paginatedUsers.page = res.currentPage
      this.paginatedUsers.pages = res.totalPages
      this.paginatedUsers.users = res.result
    }).finally(() => {
      this.spinner.hide()
    })
  }

  onSizeChange(event: Event): void {
    const size = +(event.target as HTMLSelectElement).value;
    this.tableHeadersize = size;
    this.paginatedUsers.size = this.tableHeadersize;
  }

  onPageChange(event: any): void {
    this.getUsers(event.page);
  }


  Activate(id: any) {
    this.userService.activateUser(id).then((res) => {
      this.getUsers(this.paginatedUsers.page);
    })
  }

  /*  UserGroup(id:any){
      this.group_service.all_users_group(id).then((res)=>{
        return
      })
    }*/
  hasAccess(): boolean {
    return this.LoggedUser.role.role === 'ROLE_ADMIN' || this.LoggedUser.privileges.some(priv => priv.name === 'USER_WRITE');
  }
}
