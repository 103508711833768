import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private http: HttpClient,
  ) { }


  all_reports(page: number,size: number,contenttitle?:string) {
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size)

    if (contenttitle) httpParams = httpParams.append('contenttitle', contenttitle);

    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/report/all_reports`, { params: httpParams }));
  }

  delete_report(report_id:any){
    return lastValueFrom(this.http.patch<any>(`${environment.apiUrl}/api/v1/report/delete_report/${report_id}`,{}));
  }


  add_report_content(data:any){
    return lastValueFrom(this.http.post<any>(`${environment.apiUrl}/api/v1/report_content/add_content`,data));
  }


  add_report_content_description(content_id:any,data:any){
    return lastValueFrom(this.http.patch<any>(`${environment.apiUrl}/api/v1/report_content/add_content_decriptions/${content_id}`,data));
  }


  update_report_content_description(content_id:any,data:any){
    return lastValueFrom(this.http.patch<any>(`${environment.apiUrl}/api/v1/report_content/update_content/${content_id}`,data));
  }


  contents_reports(page: number,size: number,title?:string){
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size)

    if (title) httpParams = httpParams.append('title', title);

    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/report_content/all_contents`, { params: httpParams }));
  }

  delete_content(content_id:any){
    return lastValueFrom(this.http.patch<any>(`${environment.apiUrl}/api/v1/report_content/delete_content/${content_id}`, {}));
  }
}
