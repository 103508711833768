import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SubadminsService {

  constructor(
    private http: HttpClient,
  ) { }


  all_sub_admins(page: number,size: number,name?:string,active?:boolean) {
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size)

    if (name) httpParams = httpParams.append('name', name);
    if (active) httpParams = httpParams.append('active', active);

    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/sub_admin/search_sub_admin_list`, { params: httpParams }));
  }

  add_sub_admin(sub_data:any){
    return lastValueFrom(this.http.post<any>(`${environment.apiUrl}/api/v1/sub_admin/add_sub_admin`, sub_data));
  }
}
