import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {OnInit} from '@angular/core';
import {SettingsService} from "../../services/Api/Settings/settings.service";
import {User} from "../../models/user";
import {DialogService} from "../../services/others/dialog.service";
import {FormControl, FormGroup} from '@angular/forms';
import {MediaService} from "../../services/others/media.service";
import {SharedService} from "../../services/Api/shared/shared.service";
import {GlobalService} from "../../services/others/global.service";
import {AuthService} from "../../services/Api/Auth/auth.service";
import {environment} from "../../../environments/environment";
import {user} from "@angular/fire/auth";
import {ToastService} from "../../services/others/toast.service";
import {LanguageService} from "../../services/others/language.service";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  isArabic = false;
  email = '';
  password = '';
  password2 = '';
  language = 'English';
  importantUpdates = false;
  checkInsAndLocations = false;
  allowResetPassword = false;
  LoggedUser: any;
  profile: any
  viewProfile: any
  ROOT = environment.cdn

  constructor(private sharedService: SharedService,private translation: LanguageService,private Toast: ToastService,private glovar: GlobalService, private AuthService: AuthService, private translate: TranslateService, private service: SettingsService, private dialogService: DialogService, private media: MediaService, private authService: AuthService) {
    this.authService.CurrentUser().then((res) => {
      this.LoggedUser = res;
      this.email = res?.email;
    });
    this.language = translate.currentLang;
  }

  ngOnInit() {
    this.Get_Settings();
    this.checkIsArabic();
  }

  change(localeCode:any){
    this.translation.setLanguage(localeCode)
    window.location.reload();
  }

  checkIsArabic() {
    this.isArabic = this.translate.currentLang === 'ar';
  }

  onSave() {
    this.Update()
  }


  Get_Settings() {
    this.service.all_settings().then((res) => {
      this.checkInsAndLocations = res.allowchekinsandlocation;
      this.allowResetPassword = res.allowresetpassword;
    });
  }

  Update() {
    this.service.update_settings(this.checkInsAndLocations, this.allowResetPassword).then((res) => {
      this.Get_Settings();
    });
  }

  updatephoto() {
    this.media.UpdateImg(this.LoggedUser.id, this.profile)?.then((res) => {
      this.sharedService.updateUserProfileImage(res.profileimage);
    })
  }

  openViewDialog(title: string, user?: User) {
    this.dialogService.openDialog(title, user);
  }

  onCheckInsChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (!checkbox.checked) {
      this.openViewDialog('Settings_Alert');
    }
  }

  getProfileImage(e: any) {
    if (e.target.files.length > 0) {
      this.profile = e.target.files[0];
      this.glovar
        .getImg(this.profile)
        .then((result) => (this.viewProfile = result));
    }
  }

  async update_pass() {
    if (this.password === '' || this.password2 === '') {
      this.Toast.ShowToastError(this.translate.instant('PLZ_FILL'),this.translate.instant('MSG.WARNING'))
    } else {
      const data = { oldpassword: this.password, password: this.password2 };
      await this.authService.reset_pass(data).then((res)=>{
        this.Get_Settings();
        this.password = "";
        this.password2 = "";
        this.Toast.ShowToastSuccess(this.translate.instant('PWD_CHNG_SUCC'), this.translate.instant('MSG.SUCCESS'));
      }).catch((err)=>{

        this.Toast.ShowToastError(this.translate.instant(err.error), this.translate.instant('MSG.WARNING'));
      })

    }
  }

  hasAccess(): boolean {
    return this.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv?.name === 'GLOBAL_SETTINGS_WRITE');
  }
}
