<div class="user-details">
    <form [formGroup]="subadminForm" >
<!--      <div class="w-100 fxRow fxAlignCenterCenter" #picture>-->
<!--        <div class="w-100 fxRow fxAlignCenterCenter">-->
<!--          <div>-->
<!--            <div (click)="picture_file.click()" class="bgi bgi_avatar w-5rem h-5rem" *ngIf="!profile"-->
<!--                 [style.background-image]="'url(/assets/images/useraddimage.png)'"></div>-->
<!--            <div (click)="picture_file.click()" class="bgi bgi_avatar w-5rem h-5rem br50" *ngIf="profile"-->
<!--                 [style.background-image]="'url(' +viewProfile+')'"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <input type="file" #picture_file hidden formControlName="profile" accept="image/png"-->
<!--               (change)="getProfileImage($event)">-->
<!--      </div>-->

      <div class="form-grid" [class.ar]="CurrentLang === 'ar'">
        <div class="form-group">
          <label for="name">{{ 'NAME' | translate }}</label>
          <input type="text" id="name" formControlName="name">
        </div>
        <div class="form-group">
          <label for="phoneNumber">{{ 'PHONE_NUMBER' | translate }}</label>
          <input type="tel" id="phoneNumber" formControlName="phoneNumber">
        </div>
        <div class="form-group">
          <label for="email">{{ 'EMAIL' | translate }}*</label>
          <input type="email" id="email" formControlName="email" required>
        </div>
        <div class="form-group">
          <label for="password">{{ 'PASSWORD' | translate }}*</label>
          <input type="password" id="password" formControlName="password" required>
        </div>
      </div>

      <div class="button-group">
        <button type="button" class="action-btn bgcolor1 br" (click)="onSubmit()" [disabled]="!subadminForm.valid">{{ 'SAVE' | translate }}</button>
        <button type="button" class="action-btn br color1" (click)="onCancel()">{{ 'CANCEL' | translate }}</button>
      </div>
    </form>
  </div>
