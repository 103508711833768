import {AfterViewInit, Component} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/Api/Auth/auth.service';
import {ToasterService} from "@coreui/angular";
import {ToastService} from "../../services/others/toast.service";
import {CookieService} from "ngx-cookie-service";
import {DeviceDetectorService} from "ngx-device-detector";
import {MessagingService} from "../../services/FirebaseMsg/messaging-service";
import {DeviceUUID} from "device-uuid";
import {SharedService} from "../../services/Api/shared/shared.service";
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements AfterViewInit{
  currentLanguage: string;
  LoggedUser:any
  sidebarItems: { route: string, icon: string, label: string, title: string }[] = [
    { route: '/home', icon: 'fa-gauge', label: 'SIDEBAR.DASHBOARD',title:'SIDEBAR.DASHBOARD',},
    { route: '/users', icon: 'fa-users', label: 'SIDEBAR.USERS',title:'USER_READ' },
    { route: '/groups', icon: 'fa-people-group', label: 'SIDEBAR.GROUPS',title:'GROUP_READ' },
    { route: '/events', icon: 'fa-calendar-check', label: 'SIDEBAR.EVENTS',title:'EVENT_READ' },
    { route: '/manage-subadmins', icon: 'fa-user-tie', label: 'SIDEBAR.MANAGE_SUBADMINS' ,title:'SUB_ADMIN_READ'},
    { route: '/notifications', icon: 'fa-bell', label: 'SIDEBAR.NOTIFICATIONS',title:'NOTIFICATION_READ' },
    { route: '/reports', icon: 'fa-chart-pie', label: 'SIDEBAR.REPORTS',title:'REPORT_READ' },
    { route: '/settings', icon: 'fa-gear', label: 'SIDEBAR.SETTINGS' ,title:'GLOBAL_SETTINGS_READ'},
    { route: '/Sign-In', icon: 'fa-arrow-right-from-bracket', label: 'SIDEBAR.LOGOUT',title:'SIDEBAR.LOGOUT' }

  ];

  hasAccess(title: string): boolean {
    return this.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv.name === title);
  }

  constructor(private toastService: ToastService,private shared: SharedService,private messagingService: MessagingService,private deviceService: DeviceDetectorService,private cookieService: CookieService,private auth: AuthService,private router: Router,private Toast: ToastService, private translate: TranslateService,private AuthService:AuthService) {
    this.currentLanguage = this.translate.currentLang;

    // Subscribe to language change events
    this.translate.onLangChange.subscribe((event) => {
      this.currentLanguage = event.lang;

    });
  }
  isRouteActive(route: string): boolean {
    // Extract the base path from the current URL
    const currentPath = this.router.url.split('?')[0];
    return currentPath.startsWith(route);
  }

  navigateTo(route: string, title: string): void {
    if (this.hasAccess(title) || title === 'SIDEBAR.DASHBOARD' || title === 'SIDEBAR.LOGOUT') {
      this.router.navigate([route]);
    } else {
      this.Toast.ShowToastError(this.translate.instant('NOT_ALLOWED'), this.translate.instant('MSG.WARNING'));
    }
  }

  ngAfterViewInit(): void {
    this.AuthService.CurrentUser().then((res) => {
      this.LoggedUser = res;

    });
  }


  Logout(){
    let obj = {
      deviceInfo: {
        deviceId: new DeviceUUID().get(),
        deviceType: this.deviceService.deviceType,
        tokendevice: this.messagingService.getToken(),
      },
      token: this.cookieService.get("TOKEN_DASH_WAYNEK")
    }
    this.auth.logoutUser(obj).then((res)=>{

    }).catch().finally(()=>{
      this.cookieService.set("STATE_DASH_WAYNEK", "false");
      this.shared.changeUser(null);
      this.toastService.ShowToastSuccess(this.translate.instant('LOGOUT.description'), this.translate.instant('LOGOUT.title'));

      this.router.navigate(['/Sign-In']);
    })
  }

}
