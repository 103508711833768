import {AfterViewInit, Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessagingService } from './services/FirebaseMsg/messaging-service';
import { Router } from '@angular/router';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import {AuthService} from "./services/Api/Auth/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],  // Corrected 'styleUrl' to 'styleUrls'
  providers: [MessageService]
})
export class AppComponent implements OnInit,AfterViewInit {
  title = 'WaynekDashboard';
  isArabic = false;
  message: any;
  LoggedUser:any;
  constructor(
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private translate: TranslateService,
    private router: Router,
    protected msg: MessagingService,
    private authService: AuthService,
  ) {
    this.primengConfig.ripple = true;
    this.authService.CurrentUser().then((res) => {
      this.LoggedUser = res;
    });
  }

  ngOnInit() {
    // this.msg.RequestPermission();
    // this.msg.ReceiveMessaging();
    // this.msg.currMsg.subscribe((msg) => {
    //   this.message = msg;
    //   if (this.message && this.message.notification) {
    //     this.showToast(this.message.notification.title, this.message.notification.body);
    //   }
    // });
    this.msg.getToken().subscribe(token => {

    });
    this.msg.receiveMessage().subscribe(message => {

    });
  }

  isRouteActive(route: string): boolean {
    const currentPath = this.router.url.split('?')[0];
    return currentPath === route;
  }

  showToast(title: string, body: string) {
    this.messageService.add({
      severity: 'info',
      summary: title,
      detail: body,
      life: 5000 // Toast duration in milliseconds
    });
  }

  ngAfterViewInit() {
    this.isArabic = this.translate.currentLang === 'ar';
  }

  hasAccess(): boolean {
    return this.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv?.name === 'BROADCAST_MESSAGES_READ');
  }
}
