<div class="user-details">
  <form [formGroup]="reportContentForm">
    <div class="form-grid" [class.ar]="CurrentLang === 'ar'">
      <div class="form-group">
        <label >{{ 'CONTENTS' | translate }}</label>
        <div formArrayName="contents" class="form-group-rows-conatiner">
          <div *ngFor="let contentGroup of contents.controls; let i = index" [formGroupName]="i" class="form-group-rows">
            <input type="text" formControlName="content" required>
            <button type="button" class="btn btn-danger" (click)="removeContent(i)">{{ 'REMOVE_ITEM' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="button-group">
      <button type="button" class="action-btn bgcolor1 br" (click)="onSubmit()" >{{ 'SAVE' | translate }}</button>
      <button type="button" class="action-btn br color1" (click)="onCancel()">{{ 'CANCEL' | translate }}</button>
      <button type="button" class="action-btn br color1 bgcolor-white" (click)="addContent()">{{ 'ADD_ITEM' | translate }}</button>
    </div>
  </form>
</div>
