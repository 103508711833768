import {Directive, ElementRef, Renderer2} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Directive({
  selector: 'input'
})
export class InputDirective {

  constructor(private el: ElementRef, private renderer: Renderer2, private translate: TranslateService) { }

  ngOnInit() {
    this.setTextDirection();
  }

  private setTextDirection() {
    const currentLang = this.translate.currentLang;
    if (currentLang === 'ar') {
      this.renderer.setStyle(this.el.nativeElement, 'direction', 'rtl');
    } else {
      this.renderer.removeStyle(this.el.nativeElement, 'direction');
    }
  }

}
