<div class="circle1"></div>
<div class="circle2"></div>
<img src="/assets/images/WaynekLogo.png" alt="Waynek Logo" class="logo-animation">
<div class="login-section">
  <div class="login-container">
    <img src="/assets/images/Logo.png" alt="Waynek Logo" class="logo">
    <h2 class="PSBold">{{'LOGIN.PAGE.title' | translate}}</h2>
    <h3 class="PSBold">{{'LOGIN.PAGE.description' | translate}}</h3>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" style="padding:  0 1vw;">
      <label for="full-name PM">{{'LOGIN.PAGE.fullName' | translate}}</label>
      <div style="padding:0.5vw;width: 100%;">
        <input type="text" id="full-name" formControlName="fullName" placeholder="Enter your full name">
      </div>
      <label for="password PM">{{'LOGIN.PAGE.password' | translate}}</label>
      <div class="password-container" style="padding:  0.5vw 1vw;">
        <input [type]="showPassword ? 'text' : 'password'" id="password" formControlName="password" placeholder="Enter your password">
        <span class="toggle-password" (click)="togglePasswordVisibility()">
          <i [ngClass]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
        </span>
      </div>
      <a href="#" class="forgot-password PR">{{'LOGIN.PAGE.forgotPassword' | translate}}</a>
      <button type="submit" style="margin-bottom: 2vw;">{{'LOGIN.PAGE.signIn' | translate}}</button>
    </form>
  </div>
</div>