import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from '../../services/others/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {Event, Groups} from '../../models/user';
import {EventService} from "../../services/Api/events/event.service";
import {NgxSpinnerService} from "ngx-spinner";
import {PaginatedEvents, PaginatedUsers} from "../../models/paginators";
import {ToastService} from "../../services/others/toast.service";
import {AuthService} from "../../services/Api/Auth/auth.service";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss'
})
export class EventsComponent {
  isArabic:boolean = false;
  verified:boolean = false;
  search:string = "";
  message: string | null = null;
  paginatedEvents: PaginatedEvents = { size: 5, nb_events: 0, page: -1, events: [], pages: 1 }
  tableHeadersize: number = 5;
  LoggedUser:any;
  tableHeaders = [
    { key: 'NAME_EVENT', sortable: true },
    { key: 'DESCRIPTION', sortable: true },
    { key: 'START_DATE', sortable: true },
    { key: 'END_DATE', sortable: true },
    { key: 'CREATED_BY', sortable: true },
    { key: 'ACTIONS', sortable: false }
  ];

  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
    private Toaster: ToastService,
    private service: EventService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private router: Router) {
    this.authService.CurrentUser().then((res) => {
      this.LoggedUser = res;
    });

    this.checkIsArabic();
    this.Events(0)
  }


  checkIsArabic() {
    this.isArabic = this.translate.currentLang === 'ar';
  }

  Events(page: number) {
    this.spinner.show()
    const size = this.paginatedEvents.size || 5;
    this.service.getEvents(page, size, this.search,this.search,this.verified).then((res) => {
      this.spinner.hide()
      this.paginatedEvents.nb_events = res.totalItems
      this.paginatedEvents.page = res.currentPage
      this.paginatedEvents.pages = res.totalPages
      this.paginatedEvents.events = res.result
    }).finally(()=>{
      this.spinner.hide()
    })
  }


  Delete(id: any) {
    if (confirm("Are you sure to delete ?")) {
      this.service.Delete_Event(id).then((res) => {
        this.Toaster.ShowToastSuccess(this.translate.instant("MSG.EVENT_DELETED"), this.translate.instant("MSG.SUCCESS"));
      }).finally(() => {
        this.Events(0)
      })
    }
  }

  openViewDialog(title: string, event: any) {
    this.dialogService.openDialog(title, event);
  }

  onSizeChange(event: any): void {
    const size = +(event.target as HTMLSelectElement).value;
    this.tableHeadersize = size;
    this.paginatedEvents.size = this.tableHeadersize;
  }

  hasAccess(): boolean {
    return this.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv?.name === 'EVENT_WRITE');
  }
  onPageChange(event: any): void {
    this.Events(event.page);
  }
}
