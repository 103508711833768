import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SubAdminsLastActionsService {

  constructor(
    private http: HttpClient,
  ) { }


  all_actions(page: number,size: number) {
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size)


    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/users_actions/last_actions_of_all_sub_admins`, { params: httpParams }));
  }
}
