import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PrivilegeService {

  constructor(
    private http: HttpClient,
  ) { }


  all_prev() {
    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/privileges/get_all_privileges`));
  }


  add_prev(user_id: number, idList: number[]): Promise<any> {


    const idListParam = idList.join(',');

    const params = new HttpParams().set('idList', idListParam);

    // Construct the full URL with user_id
    const url = `${environment.apiUrl}/api/v1/privileges/add_user_privileges/${user_id}`;

    // Send PATCH request with query parameters
    return lastValueFrom(
      this.http.patch<any>(url, null, { params })
    ).catch(error => {
      console.error('Error adding privileges:', error);
      throw error; // Re-throw error if necessary
    });
  }

  remove_prev(user_id: number, idList: number[]){


    const idListParam = idList.join(',');

    const params = new HttpParams().set('idList', idListParam);

    // Construct the full URL with user_id
    const url = `${environment.apiUrl}/api/v1/privileges/remove_user_privileges/${user_id}`;

    // Send PATCH request with query parameters
    return lastValueFrom(
      this.http.patch<any>(url, null, { params })
    ).catch(error => {
      console.error('Error adding privileges:', error);
      throw error; // Re-throw error if necessary
    });
  }

}
