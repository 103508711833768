import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {DialogService} from '../../../services/others/dialog.service';
import {CheckInsService} from "../../../services/Api/Check-ins/check-ins.service";
import {PaginatedCheckIns} from "../../../models/paginators";
import {NgxSpinnerService} from "ngx-spinner";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

@Component({
  selector: 'check-ins-locations',
  templateUrl: './check-ins-locations.component.html',
  styleUrl: './check-ins-locations.component.scss'
})
export class CheckInsLocationsComponent {
  isArabic = false;
  message: string | null = null;
  CheckIN:string = "";
  tableHeadersize: number = 5;
  paginatedCheckINs: PaginatedCheckIns = {size: 5, nb_check_ins: 0, page: -1, check_ins: [], pages: 1}
  tableHeaders = [
    { key: 'USER', sortable: true },
    { key: 'DATE', sortable: true },
    { key: 'LOCATION', sortable: true },
    { key: 'RADIUS', sortable: true },
    { key: 'GROUP', sortable: true },
    { key: 'TIME', sortable: true },
    { key: 'ACTIONS', sortable: false }
  ];
  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private service: CheckInsService,
    private router: Router
  ) {
    this.checkIsArabic();
    this.GetAllCheckINs(0)
  }


  GetAllCheckINs(page?:any){
    this.spinner.show()
    const size = this.paginatedCheckINs.size || 5;
    this.service.all_checkins(page,size,this.CheckIN).then((res)=>{
      this.spinner.hide()
      this.paginatedCheckINs.nb_check_ins = res.totalItems
      this.paginatedCheckINs.page = res.currentPage
      this.paginatedCheckINs.pages = res.totalPages
      this.paginatedCheckINs.check_ins = res.result
    }).finally(() => {
      this.spinner.hide()
    })
  }

  checkIsArabic() {
    this.isArabic = this.translate.currentLang === 'ar';
  }

  openViewDialog(title:string,activity: any) {
    this.dialogService.openDialog(title, activity);
  }

  onSizeChange(event: Event): void {
    this.tableHeadersize = +(event.target as HTMLSelectElement).value;
    this.paginatedCheckINs.size = this.tableHeadersize;
  }


  formatTimestampToTime(timestamp: number): string {
    // Create a Date object from the timestamp
    const date = new Date(timestamp);

    // Extract hours, minutes, and seconds
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Format the time as HH:mm:ss
    return `${hours}:${minutes}:${seconds}`;
  }

  onPageChange(event: any): void {
    this.GetAllCheckINs(event.page);
  }

  onExportChange(event: Event): void {
    const value = (event.target as HTMLSelectElement).value;
    if (value === 'pdf') {
      this.exportPDF(this.paginatedCheckINs.check_ins);
    } else if (value === 'csv') {
      this.exportCSV();
    }
  }


  exportPDF(data: any[]) {
    if (!data || data.length === 0) {
      console.error("No data provided for PDF generation.");
      return;
    }

    const doc = new jsPDF('p', 'mm', 'a4', true);
    const padding = 10;
    const headerColor = '#31317a';
    const maxHeight = doc.internal.pageSize.height - 20; // Maximum height available for content
    let yOffset = 20; // Initial vertical position for the first block

    const addBlockTitle = (title: string, yPosition: number): number => {
      doc.setFontSize(16);
      doc.text(title, padding, yPosition);
      doc.setLineWidth(0.5);
      doc.line(padding, yPosition + 2, doc.internal.pageSize.width - padding, yPosition + 2);
      return yPosition + 10;
    };

    const getSafe = (obj: any, path: string[], defaultValue: any = ''): any => {
      return path.reduce((acc, key) => acc && acc[key] !== undefined ? acc[key] : defaultValue, obj);
    };

    data.forEach((res, index) => {
      if (yOffset + 30 > maxHeight) { // Check if there is enough space for the next block
        doc.addPage(); // Add a new page if not enough space
        yOffset = 20; // Reset yOffset for the new page
      }

      const title =  "Check_In_Num_"+(index+1);
      doc.setFontSize(20);
      doc.text(title, doc.internal.pageSize.width / 2, yOffset, { align: 'center' });

      yOffset += 10; // Adjust yOffset for spacing after title

      yOffset = addBlockTitle('Information', yOffset);

      autoTable(doc, {
        startY: yOffset,
        head: [['Field', 'Value']],
        body: [
          ['User', getSafe(res, ['username'])],
          ['Date', this.formatTimestamp(getSafe(res, ['timestamp']))],
          ['LOCATION', getSafe(res, ['location'])?getSafe(res, ['location']):'-'],
          ['RADIUS', getSafe(res, ['checkdiameter'])],
          ['Group', getSafe(res, ['groupname'])],
          ['Time', this.formatTimestampToTime(getSafe(res, ['timestamp']))],
        ],
        theme: 'grid',
        headStyles: { fillColor: headerColor },
        styles: { font: 'amiri', fontSize: 10 },
        didDrawPage: (data:any) => {
          // This function will ensure we don't exceed the maximum height
          if (data.cursor.y + data.table.height > maxHeight) {
            doc.addPage();
            yOffset = 20;
          }
        }
      });

      yOffset = (doc as any).autoTable.previous.finalY + 10; // Adjust yOffset after the table
    });

    doc.save('Reports.pdf');
  }


  exportCSV(): void {
    // Exclude the 'Actions' column from the headers
    const headers = this.tableHeaders
      .filter(header => header.key !== 'ACTIONS') // Filter out the 'Actions' column
      .map(header => header.key);

    const rows = [
      headers,
      ...this.paginatedCheckINs.check_ins.map(report => [
        report.username,
        report.timestamp ? new Date(report.timestamp).toLocaleDateString() : '-',
        report.location?report.location.name:'-',
        report.checkdiameter,
        report.groupname,
        this.formatTimestampToTime(report.timestamp) ,
      ])
    ];

    const csvContent = rows.map(e => e.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'check_ins.csv');
    link.click();
  }

  formatTimestamp(timestamp: number): string {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthsOfYear = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const date = new Date(timestamp);

    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = String(date.getDate()).padStart(2, '0');
    const month = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const amPm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${dayOfWeek}, ${day} ${month} ${year}`;
  }
}
