import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DialogService } from '../../services/others/dialog.service';
import { Router } from '@angular/router';
import { Report } from '../../models/user';
import {ReportsService} from "../../services/Api/Reports/reports.service";
import {NgxSpinnerService} from "ngx-spinner";
import {PaginatedGroups, PaginatedReports} from "../../models/paginators";
import {AuthService} from "../../services/Api/Auth/auth.service";
import {ToastService} from "../../services/others/toast.service";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss'
})
export class ReportsComponent implements OnInit{
  isArabic = false;
  Title:string = "";
  tableHeadersize: number = 5;
  message: string | null = null;
  LoggedUser:any;
  PaginatedReports: PaginatedReports = {size: 5, nb_reports: 0, page: -1, reports: [], pages: 1}
  tableHeaders = [
    { key: 'ID', sortable: true },
    { key: 'USER', sortable: true },
    { key: 'DATE_TIME', sortable: true },
    { key: 'TYPE_OF_CONTENT', sortable: true },
    { key: 'CONTENT', sortable: true },
    { key: 'ACTION', sortable: false }
  ];

  private deleteUserSubscription: Subscription;

  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
    private service: ReportsService,
    private Toaster: ToastService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private router: Router)
  {
    this.authService.CurrentUser().then((res) => {
      this.LoggedUser = res;
    });
    this.checkIsArabic();
    this.getReports(0)
    this.deleteUserSubscription = this.dialogService.deleteUser$.subscribe(user => {
      this.getReports(0)
    });
  }

  ngOnInit() {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.message = navigation.extras.state['message'];


      // Optionally, clear the message after displaying
      setTimeout(() => this.message = null, 5000);
    }
  }

  onExportChange(event: Event): void {
    const value = (event.target as HTMLSelectElement).value;
    if (value === 'pdf') {
      this.exportPDF(this.PaginatedReports.reports);
    } else if (value === 'csv') {
      this.exportCSV();
    }
  }

  openViewUserDialog(report: Report) {
    this.dialogService.openDialog('VIEW_REPORT', report);
  }

  checkIsArabic() {
    this.isArabic = this.translate.currentLang === 'ar';
  }


  getReports(page: number) {
    this.spinner.show()
    const size = this.PaginatedReports.size || 5;
    this.service.all_reports(page, size,this.Title).then((res) => {
      this.spinner.hide()
      this.PaginatedReports.nb_reports = res.totalItems
      this.PaginatedReports.page = res.currentPage
      this.PaginatedReports.pages = res.totalPages
      this.PaginatedReports.reports = res.result
    }).finally(() => {
      this.spinner.hide()
    })
  }


  onSizeChange(event: Event): void {
    const size = +(event.target as HTMLSelectElement).value;
    this.tableHeadersize = size;
    this.PaginatedReports.size = this.tableHeadersize;
  }

  Delete(id: any) {
    if (confirm("Are you sure to delete ?")) {
      this.service.delete_report(id).then((res) => {
        this.Toaster.ShowToastSuccess(this.translate.instant('MSG.REPORT_DELETED'), this.translate.instant('MSG.SUCCESS'));
      }).finally(() => {
        this.getReports(this.PaginatedReports.page)
      })
    }
  }

  hasAccess(): boolean {
    return this.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv?.name === 'REPORT_WRITE');
  }

  // exportPDF(): void {
  //   const data = document.getElementById('table-container');
  //   if (data) {
  //     html2canvas(data, { scale: 2 }).then(canvas => {
  //       const imgWidth = 208; // Width of the A4 page in mm
  //       const pageHeight = 295; // Height of the A4 page in mm
  //       const imgHeight = canvas.height * imgWidth / canvas.width;
  //       let heightLeft = imgHeight;
  //       const doc = new jsPDF('p', 'mm', 'a4');
  //
  //       // Add Header with Custom Font
  //       doc.setFont('helvetica', 'bold');
  //       doc.setFontSize(16);
  //       doc.text('Customized Report Title', 105, 15, { align: 'center' });
  //
  //       // Add Footer
  //       doc.setFont('helvetica', 'normal');
  //       doc.setFontSize(12);
  //       const footerText = 'Page ';
  //       const totalPages = Math.ceil(imgHeight / pageHeight);
  //       for (let i = 0; i < totalPages; i++) {
  //         doc.setPage(i + 1);
  //         doc.text(footerText + (i + 1) + ' of ' + totalPages, 105, 285, { align: 'center' });
  //       }
  //
  //       // Add Image
  //       let position = 25; // Starting position after the header
  //       doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //       while (heightLeft >= 0) {
  //         doc.addPage();
  //         doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
  //         heightLeft -= pageHeight;
  //       }
  //       doc.save('reports.pdf');
  //     }).catch(error => {
  //       console.error('Error generating PDF:', error);
  //     });
  //   } else {
  //     console.error('Element with id "table-container" not found.');
  //   }
  // }

  exportPDF(data: any[]) {
    if (!data || data.length === 0) {
      console.error("No data provided for PDF generation.");
      return;
    }

    const doc = new jsPDF('p', 'mm', 'a4', true);
    const padding = 10;
    const headerColor = '#31317a';
    const maxHeight = doc.internal.pageSize.height - 20; // Maximum height available for content
    let yOffset = 20; // Initial vertical position for the first block

    const addBlockTitle = (title: string, yPosition: number): number => {
      doc.setFontSize(16);
      doc.text(title, padding, yPosition);
      doc.setLineWidth(0.5);
      doc.line(padding, yPosition + 2, doc.internal.pageSize.width - padding, yPosition + 2);
      return yPosition + 10;
    };

    const getSafe = (obj: any, path: string[], defaultValue: any = ''): any => {
      return path.reduce((acc, key) => acc && acc[key] !== undefined ? acc[key] : defaultValue, obj);
    };

    data.forEach((res, index) => {
      if (yOffset + 30 > maxHeight) { // Check if there is enough space for the next block
        doc.addPage(); // Add a new page if not enough space
        yOffset = 20; // Reset yOffset for the new page
      }

      const title =  "Report_Num_"+res.id;
      doc.setFontSize(20);
      doc.text(title, doc.internal.pageSize.width / 2, yOffset, { align: 'center' });

      yOffset += 10; // Adjust yOffset for spacing after title

      yOffset = addBlockTitle('Information', yOffset);

      autoTable(doc, {
        startY: yOffset,
        head: [['Field', 'Value']],
        body: [
          ['Id', getSafe(res, ['id'])],
          ['User', getSafe(res, ['createdbyname'])],
          ['Date', this.formatTimestamp(getSafe(res, ['timestamp']))],
          ['Type', getSafe(res, ['contenttitle'])],
          ['Content', getSafe(res, ['contentdescription'])],
        ],
        theme: 'grid',
        headStyles: { fillColor: headerColor },
        styles: { font: 'amiri', fontSize: 10 },
        didDrawPage: (data:any) => {
          // This function will ensure we don't exceed the maximum height
          if (data.cursor.y + data.table.height > maxHeight) {
            doc.addPage();
            yOffset = 20;
          }
        }
      });

      yOffset = (doc as any).autoTable.previous.finalY + 10; // Adjust yOffset after the table
    });

    doc.save('Reports.pdf');
  }


  exportCSV(): void {
    // Exclude the 'Actions' column from the headers
    const headers = this.tableHeaders
      .filter(header => header.key !== 'ACTION') // Filter out the 'Actions' column
      .map(header => header.key);

    const rows = [
      headers,
      ...this.PaginatedReports.reports.map(report => [
        report.id,
        report.createdbyname,
        report.timestamp ? new Date(report.timestamp).toLocaleDateString() : '-',
        report.contenttitle,
        report.contentdescription
      ])
    ];

    const csvContent = rows.map(e => e.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'reports.csv');
    link.click();
  }

  formatTimestamp(timestamp: number): string {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthsOfYear = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const date = new Date(timestamp);

    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = String(date.getDate()).padStart(2, '0');
    const month = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const amPm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${dayOfWeek}, ${day} ${month} ${year}, ${formattedHours}:${minutes}:${seconds} ${amPm}`;
  }
}
