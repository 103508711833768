import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {AngularFireMessaging} from "@angular/fire/compat/messaging";

@Injectable()
export class MessagingService{


  currMsg = new BehaviorSubject<any>(null);


  constructor(private angularFireMessaging:AngularFireMessaging) {
  }


  RequestPermission(){
    this.angularFireMessaging.requestToken.subscribe((token)=>{

    },(err)=>{

    })
  }


  ReceiveMessaging(){
    this.angularFireMessaging.messages.subscribe((payload)=>{

      this.currMsg.next(payload)
    })
  }


  getToken(): Observable<string | undefined> {
    return new Observable(observer => {
      this.angularFireMessaging.requestToken.subscribe(
        token => {
          if (token) {
            console.log('FCM Token:', token);
            observer.next(token);
          } else {
            // Handle the case where token is null or undefined
            console.warn('FCM Token is null or undefined');
            observer.next(undefined); // or observer.error('Token is null');
          }
        },
        error => {
          console.error('Error getting FCM token:', error);
          observer.error(error);
        }
      );
    });
  }

  receiveMessage(): Observable<any> {
    return this.angularFireMessaging.messages;
  }
}
