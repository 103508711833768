<div class="users-container" [class.arabic]="isArabic">
  <h1 class="t5">{{ 'SIDEBAR.EVENTS' | translate }}</h1>

  <div class="controls">
    <div class="left-controls">
      <select class="show-select" [(ngModel)]="tableHeadersize" (change)="onSizeChange($event)">
        <option [value]="5">5</option>
        <option [value]="10">10</option>
        <option [value]="20">20</option>
      </select>
      <input type="text" [placeholder]="'SEARCH_PLACEHOLDER' | translate" class="user-search" [(ngModel)]="search">
      <button class="apply-btn" [class.arabic]="isArabic" (click)="Events(0)">{{ 'APPLY' | translate }}</button>
    </div>
    <div class="right-controls">
      <select class="sort-select" [(ngModel)]="verified">
        <option disabled>{{ 'SELECT' | translate }}</option>
        <option value="false">{{ 'ACTIVE' | translate }}</option>
        <option value="true">{{ 'SUSPENDED' | translate }}</option>
      </select>
    </div>
  </div>

  <table class="users-table">
    <thead>
    <tr>
      <th [class.arabic]="isArabic" *ngFor="let header of tableHeaders">
        {{ header.key | translate }}
        <!-- <span *ngIf="header.sortable" class="sort-icon">▼</span> -->
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let event of paginatedEvents.events">
      <td>{{ event.eventname }}</td>
      <td>{{ event.description }}</td>
      <td>{{ event.startdate | date }}</td>
      <td>{{ event.enddate | date }}</td>
      <td>{{ event.createdbyname }}</td>
      <td>
        <span class="action-icon" (click)="openViewDialog('CHECKED_MEMBERS',event)" [title]="'CHECKED_MEMBERS' | translate">
          <i class="fa-solid fa-square-check t2 color10"></i>
        </span>
        <span class="action-icon"  (click)="openViewDialog('INTERESTED_MEMBERS',event)" [title]="'INTERESTED_MEMBERS' | translate">
          <i class="fa-solid fa-heart t2 text-danger"></i>
        </span>
        <span class="action-icon" *ngIf="hasAccess()" (click)="Delete(event.id)"  [title]="'DELETE' | translate">
          <i class="fa-solid fa-xmark t2 color1"></i>
        </span>
      </td>
    </tr>
    <tr *ngIf="paginatedEvents.events.length === 0">
      <td colspan="6" class="text-center">{{ 'No Data Found' | translate }}</td>
    </tr>
    </tbody>
  </table>
  <div class="paginator">
    <p-paginator *ngIf="paginatedEvents.events.length > 0" [rows]="paginatedEvents.size"
                 [totalRecords]="paginatedEvents.nb_events" (onPageChange)="onPageChange($event)"></p-paginator>
  </div>
</div>
