import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StatService {

  private apiUrl = `${environment.apiUrl}/api/v1/admin_stats`;

  constructor(
    private http: HttpClient,
  ) { }

  // Get check-ins count
  getCheckinsCount() {
    return lastValueFrom(this.http.get<number>(`${this.apiUrl}/checkins_count`));
  }

  // Get events count
  getEventsCount() {
    return lastValueFrom(this.http.get<number>(`${this.apiUrl}/events_count`));
  }

  // Get groups count
  getGroupsCount() {
    return lastValueFrom(this.http.get<number>(`${this.apiUrl}/groups_count`));
  }

  getUsersCount() {
    return lastValueFrom(this.http.get<number>(`${this.apiUrl}/users_count`));
  }


  // Get new users stats for a start and end date
  getNewUsersStartEndDate(startDate: string, endDate: string) {
    const params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate);

    return lastValueFrom(this.http.get<any>(`${this.apiUrl}/new_users_start_end_date`, { params }));
  }

  // Get users count

  // Get users evolution stats
  getUsersEvolution() {
    return lastValueFrom(this.http.get<any>(`${this.apiUrl}/users_evolution`));
  }


}
