import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoggedUser } from '../../../models/user';
import { UserService } from '../../../services/Api/user/user.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {
  @Input() user!: LoggedUser;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() updateuser = new EventEmitter<any>();
  CurrentLang: string = "";
  userForm: FormGroup;
  userFields = [
    { id: 'name', label: 'NAME', type: 'text', controlName: 'name' },
    { id: 'city', label: 'CITY', type: 'text', controlName: 'city' },
    { id: 'phone', label: 'PHONE_NUMBER', type: 'tel', controlName: 'phone' },
    { id: 'country', label: 'COUNTRY', type: 'text', controlName: 'country' },
  ];

  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private service: UserService
  ) {
    this.CurrentLang = this.translate.currentLang;
    this.userForm = this.fb.group({
      name: [''],
      city: [''],
      phone: [''],
      country: [''],
    });
  }

  ngOnInit() {
    if (this.user) {
      this.initFormValues();
    }
  }

  private initFormValues() {
    if (this.user) {
      this.userForm.patchValue({
        name: this.user.name,
        city: this.user.city,
        phone: this.user.phonenumber,
        country: this.user.country,
      });
    }
  }

  updateUser() {
    this.updateuser.emit(this.user);
    this.closeDialog.emit();
  }

  Update() {
    const form = this.userForm.value;

    // Create the data object with common properties
    const data: any = {
      city: form.city,
      country: form.country,
      name: form.name,
    };

    // Include phonenumber only if it has changed
    if (form.phone !== this.user.phonenumber) {
      data.phonenumber = form.phone;
    }

    // Call the service to update the user
    this.service.updateUser(this.user.id, data).then(() => {
      this.updateUser();
    });
  }
}
