<div class="view-group-container">
  <div class="creating-date">

    <span class="creating-date-text">
      <i class="fa-solid fa-calendar-days plr-0-5vw"></i>
      <strong>{{group.timestamp | date}}</strong>
    </span>
  </div>


  <div class="fxRow fxAlignCenterCenter">
    <div class="w-5rem h-5rem br50 br">
      <img [src]="group.profileimage?ROOT+'/'+group.profileimage.url:'assets/images/waynek-1.png'"
           [alt]="'PROFILE_IMAGE' | translate" class="profile-img">
    </div>
  </div>


  <div class="activity-details">
    <div class="form-grid" [class.ar]="currentLang === 'ar'" [formGroup]="GroupForm">
      <div class="form-group">
        <label for="name">{{ 'GROUP_NAME' | translate }}</label>
        <input type="text" id="name" formControlName="name" readonly>
      </div>
      <div class="form-group">
        <label for="radius">{{ 'RADIUS' | translate }}</label>
        <input type="text" id="radius" formControlName="description" readonly>
      </div>
      <div class="form-group">
        <label for="group">{{ 'NUMBER_OF_LOCATIONS' | translate }}</label>
        <input type="text" id="group" formControlName="locationsnumber" readonly>
      </div>
      <div class="form-group">
        <label for="time">{{ 'NUMBER_OF_MEMBER' | translate }}</label>
        <input type="text" id="time" formControlName="memberssnumber" readonly>
      </div>
      <div class="form-group">
        <label for="address">{{ 'NUMBER_OF_ADMINS' | translate }}</label>
        <input type="text" id="address" formControlName="adminsnumber" readonly>
      </div>
      <div class="form-group">
        <label for="city">{{ 'CREATOR' | translate }}</label>
        <input type="text" id="city" formControlName="creatorname" readonly>
      </div>
    </div>
    <div class="btns">
      <button class="okay-btn" (click)="Suspend()" *ngIf="hasAccess()">{{ 'Suspend Group' | translate }}</button>
      <button class="okay-btn" (click)="Delete()" *ngIf="hasAccess()">{{ 'Delete Group' | translate }}</button>
    </div>
  </div>
</div>
