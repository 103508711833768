import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {Client, CompatClient, Message, Stomp} from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import {AuthService} from "../../services/Api/Auth/auth.service";
import {PaginatedEvents, PaginatedMessages} from "../../models/paginators";
import {ChatService} from "../../services/Api/chat/chat.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-one-way-chat',
  templateUrl: './one-way-chat.component.html',
  styleUrls: ['./one-way-chat.component.scss']
})
export class OneWayChatComponent implements OnInit, OnDestroy {
  private stompClient: Client | null = null;
  // stompClientCompany!: CompatClient;
  messages: { content: string, timestamp?: string, isSent: boolean }[] = [];
  newMessage: string = '';
  currentLanguage: string;
  chatText: string = 'Chat';
  ser: firebase.User | null = null;
  LoggedUser:any;
  constructor(private spinner: NgxSpinnerService,private messages_service: ChatService,private authService: AuthService,private translate: TranslateService, private afAuth: AngularFireAuth) {
    this.currentLanguage = this.translate.currentLang;
    this.Messages(0)
  }

  ngOnInit(): void {
    this.setupEventListeners();
    this.connectWebSocket();
    this.afAuth.authState.subscribe(user => {
      this.ser = user;
    });
    this.authService.CurrentUser().then((res) => {
      this.LoggedUser = res;
    });
  }

  ngOnDestroy(): void {
    this.disconnectWebSocket();
  }

  private setupEventListeners(): void {
    const chatButton = document.querySelector('.chat-button') as HTMLElement;
    const chatBox = document.querySelector('.chat-box') as HTMLElement;
    const closeButton = document.querySelector('.chat-box .chat-box-header p') as HTMLElement;

    chatButton?.addEventListener('click', () => this.openChat(chatButton, chatBox));
    closeButton?.addEventListener('click', () => this.closeChat(chatButton, chatBox));
  }

  private openChat(chatButton: HTMLElement, chatBox: HTMLElement): void {
    chatButton.style.display = 'none';
    chatBox.style.visibility = 'visible';
  }

  private closeChat(chatButton: HTMLElement, chatBox: HTMLElement): void {
    chatButton.style.display = 'block';
    chatBox.style.visibility = 'hidden';
  }

  private connectWebSocket(): void {
    const serverUrl = 'https://server.waynek.net/ws';
    const socket = new SockJS(serverUrl);

    this.stompClient = new Client({
      webSocketFactory: () => socket,
      debug: (str) => {

      },
      onConnect: (frame) => {
        console.log('STOMP connection established', frame);
        this.stompClient?.subscribe('/user/queue/broadcast', (message: Message) => {
          const receivedMessage = JSON.parse(message.body);
          this.messages.push({
            content: receivedMessage.text,
            timestamp: new Date().toLocaleTimeString(),
            isSent: false
          });
        });
      },
      onStompError: (frame) => {
        console.error('STOMP error', frame.headers['message']);
        console.error('Additional details:', frame.body);
      }
    });

    // Activate the connection
    this.stompClient.activate();
  }


  private disconnectWebSocket(): void {
    if (this.stompClient) {
      this.stompClient.deactivate();
      console.log('STOMP connection closed');
    }
  }

  sendMessage(): void {
    if (this.newMessage.trim() && this.stompClient && this.stompClient.connected) {
      const message = {
        senderId: this.LoggedUser.id,
        content: this.newMessage,
        // timestamp: new Date().toLocaleTimeString()
      };
      this.stompClient.publish({ destination: '/app/admin_broadcast', body: JSON.stringify(message) });
      // this.stompClient.send(`/app/company_chat`, {}, JSON.stringify(message));
      this.messages.push({ ...message, isSent: true,timestamp: new Date().toLocaleTimeString() });
      this.newMessage = '';
    } else if (!this.stompClient || !this.stompClient.connected) {
      console.error('STOMP client is not connected');
    }
  }

  hasAccess(): boolean {
    return this.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv?.name === 'BROADCAST_MESSAGES_WRITE');
  }

  paginatedMessages: PaginatedMessages = { size: 5, nb_messages: 0, page: -1, messages: [], pages: 1 }
  Messages(page:any){
    this.spinner.show()
    this.messages_service.all_messages(page,this.paginatedMessages.size).then((res)=>{
      this.spinner.hide()
      this.paginatedMessages.nb_messages = res.totalItems
      this.paginatedMessages.page = res.currentPage
      this.paginatedMessages.pages = res.totalPages
      this.paginatedMessages.messages = res.messages
    })
    this.spinner.hide()
  }

  formatTimestamp(timestamp: number): string {
    // Convert Unix timestamp in milliseconds to a Date object
    const timestampDate = new Date(timestamp);

    // Get today's date and normalize to the start of the day
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Compare the date parts
    if (timestampDate.toDateString() === today.toDateString()) {
      // Return the time part if it's today's date
      return timestampDate.toTimeString().split(' ')[0]; // Format: HH:MM:SS
    } else {
      // Return the date part if it's not today's date
      return timestampDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    }
  }

}
