import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LoggedUser} from "../../../models/user";
import {environment} from "../../../../environments/environment";
import {EventService} from "../../../services/Api/events/event.service";
import {PaginatedEvents, paginatedMembers} from "../../../models/paginators";

@Component({
  selector: 'checked-users',
  templateUrl: './checked-users.component.html',
  styleUrl: './checked-users.component.scss'
})
export class CheckedUsersComponent implements OnInit, AfterViewInit {
  @Input() event: any | null = null;
  @Output() closeDialog = new EventEmitter<void>();
  filteredMembers: LoggedUser[] = [];
  searchTerm: string = '';
  members: LoggedUser[] = [];
  ROOT = environment.apiUrl
  @ViewChild('map') private mapContainer!: ElementRef<HTMLElement>;
  paginatedMembers: paginatedMembers = { size: 5, nb_members: 0, page: -1, members: [], pages: 1 }

  constructor(private service: EventService) {
  }

  ngOnInit() {

  }

  filterMembers() {
    this.filteredMembers = this.members.filter(member =>
      member.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }


  GetMemebers(event:any,page:any) {
    this.service.Checked_MEM(page,this.paginatedMembers.size,event.id).then((res) => {
      this.paginatedMembers.nb_members = res.totalItems
      this.paginatedMembers.page = res.currentPage
      this.paginatedMembers.pages = res.totalPages
      this.members = res.result
      this.filteredMembers = this.members;
    })
  }


  ngAfterViewInit() {
    this.GetMemebers(this.event ,0);
    this.filteredMembers = this.members;
  }

  onPageChange(event: any): void {
    this.GetMemebers(this.event ,event.page);
  }
}
