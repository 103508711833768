import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http: HttpClient,
  ) { }


  all_settings() {
    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/settings/settings`));
  }

  update_settings(allowCheckInsAndLocations: boolean, allowResetPassword: boolean) {
    let params = new HttpParams()
      .set('allowchekinsandlocation', String(allowCheckInsAndLocations))
      .set('allowresetpassword', String(allowResetPassword));

    return lastValueFrom(this.http.patch<any>(`${environment.apiUrl}/api/v1/settings/update_settings`, null, { params }));
  }
}
