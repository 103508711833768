import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../../../environments/environment";
import { lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
  ) { }

  getUsers(page: number,
    size: number,
    role: 'ROLE_ADMIN' | 'ROLE_SUB_ADMIN' | 'ROLE_USER',
    active?: boolean,
    name?: string,
    verified?: boolean) {
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size);

    if (active !== undefined) httpParams = httpParams.append('active', active.toString());
    if (name) httpParams = httpParams.append('name', name);
    if (role) httpParams = httpParams.append('role', role);
    if (verified !== undefined) httpParams = httpParams.append('verified', verified.toString());

    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/users/all`, { params: httpParams }));
  }


  activateUser(id: number) {
    return lastValueFrom(this.http.patch<any>(`${environment.apiUrl}/api/v1/users/activated/${id}`, {}));
  }

  getUserDetails(id: number) {
    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/users/get_user/${id}`));
  }

  updateUser(id: number, user: any) {
    return lastValueFrom(this.http.patch<any>(`${environment.apiUrl}/api/v1/users/update_user/${id}`, user));
  }

  usergroup(page: number,
            size: number,id: number) {
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size);
    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/group/all_user_groups/${id}`));
  }

}
