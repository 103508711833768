<div class="activity-details">
  <div class="creating-date">
    <span class="creating-date-text">
      <i class="fa-solid fa-clock plr-0-5vw"></i>
      <strong>{{formatTimestampToTime(activity.timestamp)}}</strong>
    </span>
  </div>
  <div class="form-grid" [class.ar]="currentLang === 'ar'" [formGroup]="activityForm">
    <div class="form-group">
      <label for="name">{{ 'NAME' | translate }}</label>
      <input type="text" id="name" formControlName="username" readonly>
    </div>
    <div class="form-group">
      <label for="radius">{{ 'RADIUS' | translate }}</label>
      <input type="text" id="radius" formControlName="checkdiameter" readonly>
    </div>
    <div class="form-group">
      <label for="group">{{ 'GROUP' | translate }}</label>
      <input type="text" id="group" formControlName="groupname" readonly>
    </div>
  </div>
  <app-waze-map [lat]="activity.checklatitude" [lon]="activity.checklongitude" [zoom]="8"></app-waze-map>
  <button class="okay-btn" (click)="onClose()">{{ 'OKAY' | translate }}</button>
</div>
