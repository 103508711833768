<div class="users-group-container">
  <div *ngFor="let location of locations" class="group-item">
    <div class="group-info">
      <span class="visibility-icon"><i class="fa-solid fa-location-crosshairs"></i></span>
      <span class="group-name">{{ location.name ? location.name : ('Unknown' | translate) }}</span>
    </div>
    <div class="group-type t0 cp" (click)="onLocationClick(location)">
      <i class="fa-solid fa-map-location-dot"></i> {{ 'VIEW_IN_MAP' | translate }}
    </div>
  </div>
  <app-waze-map [lat]="this.initialState.lat" [lon]=" this.initialState.lng" [zoom]="8"></app-waze-map>
</div>
