import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./Widgets/login/login.component";
import { HomeComponent } from './Widgets/home/home.component';
import { SidebarComponent } from './includes/sidebar/sidebar.component';
import { UsersComponent } from './Widgets/users/users.component';
import { GroupsComponent } from './Widgets/groups/groups.component';
import { EventsComponent } from './Widgets/events/events.component';
import { SubadminsComponent } from './Widgets/subadmins/subadmins.component';
import { NotificationsComponent } from './Widgets/notifications/notifications.component';
import { ReportsComponent } from './Widgets/reports/reports.component';
import { SettingsComponent } from './Widgets/settings/settings.component';
import {ReportMangComponent} from "./Widgets/settings/report-mang/report-mang.component";
import {authGuard} from "./includes/Guard/auth.guard";

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'Sign-In' },
  { path: 'Sign-In', component: LoginComponent },
  {
    path: '', component: SidebarComponent, canActivate: [authGuard],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'users', component: UsersComponent },
      { path: 'groups', component: GroupsComponent },
      { path: 'events', component: EventsComponent },
      { path: 'manage-subadmins', component: SubadminsComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'settings/Report/Manage', component: ReportMangComponent },
    ]
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
