<div class="user-details">

    <img [src]="user.profileimage?ROOT+user.profileimage.url:'assets/images/waynek-1.png'" [alt]="'PROFILE_IMAGE' | translate" class="profile-image">

    <div class="form-grid" [class.ar]="CurrentLang === 'ar'">
      <div class="form-group">
        <label for="name">{{ 'NAME' | translate }}</label>
        <input type="text" id="name" [value]="user.name" readonly>
      </div>
      <div class="form-group">
        <label for="city">{{ 'CITY' | translate }}</label>
        <input type="text" id="city" [value]="user.city" readonly>
      </div>
      <div class="form-group">
        <label for="phone">{{ 'PHONE_NUMBER' | translate }}</label>
        <input type="tel" id="phone" [value]="user.phonenumber" readonly>
      </div>
      <div class="form-group">
        <label for="country">{{ 'COUNTRY' | translate }}</label>
        <input type="text" id="country" [value]="user.country" readonly>
      </div>
      <div class="form-group">
        <label for="register-date">{{ 'REGISTER_DATE' | translate }}</label>
        <input type="text" id="register-date" [value]="user.timestmp | date" readonly>
      </div>
      <div class="form-group">
        <label for="groups">{{ 'NUMBER_OF_GROUPS' | translate }}</label>
        <input type="number" id="groups" [value]="user.groupsnumber" readonly>
      </div>
    </div>

    <button class="delete-btn" (click)="deleteUser()" *ngIf="hasAccess()">{{ 'DELETE' | translate }}</button>
  </div>
