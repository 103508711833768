import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ReportsService} from "../../../../services/Api/Reports/reports.service";
import {ToastService} from "../../../../services/others/toast.service";

@Component({
  selector: 'add-desc-content',
  templateUrl: './add-desc-content.component.html',
  styleUrl: './add-desc-content.component.scss'
})
export class AddDescContentComponent implements AfterViewInit,OnInit{
  @Input() ContentReport!: any;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() add_description_ContentEvent = new EventEmitter<any>();
  CurrentLang:string=""
  reportContentForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private service: ReportsService,
    private Toaster: ToastService
  ) {
    this.CurrentLang = translate.currentLang;
  }

  ngOnInit(): void {
    this.reportContentForm = this.fb.group({
      contents: this.fb.array([this.createContent()], Validators.required)
    });
  }

  ngAfterViewInit() {
  }

  get contents(): FormArray {
    return this.reportContentForm.get('contents') as FormArray;
  }

  createContent(): FormGroup {
    return this.fb.group({
      content: ['', Validators.required]
    });
  }

  addContent(): void {
    this.contents.push(this.createContent());
  }

  removeContent(index: number): void {
    this.contents.removeAt(index);
  }

  onSubmit(): void {
    if (this.reportContentForm.valid) {
      let form = this.reportContentForm.value;
      let data = new FormData();
      let strings: any[] = [];
      form.contents.forEach((res: any) => {
        strings.push(res.content);
      });
      data.append('contents', JSON.stringify(strings));


      this.service.add_report_content_description(this.ContentReport.id,data).then((res) => {
        this.Toaster.ShowToastSuccess(this.translate.instant("MSG.CONTENT_DESCRIPTION_ADDED"), this.translate.instant("MSG.SUCCESS"));
        this.add_description_ContentEvent.emit(data);
        this.closeDialog.emit();
      });
    }else{
      this.Toaster.ShowToastError(this.translate.instant("MSG.FILL_THE_FIELDS"), this.translate.instant("MSG.WARNING"));
    }
  }

  onCancel(): void {
    this.closeDialog.emit();
  }
}
