import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Groups} from "../../../models/user";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {GroupsService} from "../../../services/Api/Groups/groups.service";
import {UserService} from "../../../services/Api/user/user.service";
import {ToastService} from "../../../services/others/toast.service";
import {AuthService} from "../../../services/Api/Auth/auth.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'Group-Details',
  templateUrl: './group-detils.component.html',
  styleUrl: './group-detils.component.scss'
})
export class GroupDetilsComponent implements OnInit{
  @Input() group!: Groups;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() delete_group = new EventEmitter<any>();
  @Output() suspend_group = new EventEmitter<any>();
  currentLang: string = '';
  GroupForm: FormGroup;
  LoggedUser:any;
  ROOT = environment.cdn
  constructor(
    private translate: TranslateService,
    private toastService: ToastService,
    private service: GroupsService,
    private fb: FormBuilder,
    private authService: AuthService,
  ) {
    this.authService.CurrentUser().then((res) => {
      this.LoggedUser = res;
    });
    this.currentLang = this.translate.currentLang;
    this.GroupForm = this.fb.group({
      name: [''],
      description: [''],
      locationsnumber: [''],
      memberssnumber: [''],
      adminsnumber: [''],
      creatorname: ['']
    });
  }

  ngOnInit() {
    if (this.group) {
      this.GroupForm.patchValue(this.group);
    }
  }

  onClose() {
    this.closeDialog.emit();
  }

  Suspend_Grp() {
    this.suspend_group.emit(this.group);
    this.closeDialog.emit();
  }

  Delete_Grp() {
    this.delete_group.emit(this.group);
    this.closeDialog.emit();
  }

  Suspend(){
    this.service.suspend_group(this.group?.id).then((res)=>{
      this.toastService.ShowToastSuccess(this.translate.instant('MSG.GROUP_SUSPENDED'), this.translate.instant('MSG.SUCCESS'));
      this.Suspend_Grp();
    })
  }


  Delete(){
    this.service.delete_group(this.group?.id).then((res)=>{
      this.toastService.ShowToastSuccess(this.translate.instant('MSG.GROUP_DELETED'), this.translate.instant('MSG.SUCCESS'));
      this.Delete_Grp();
    })
  }

  hasAccess(): boolean {
    return this.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv?.name === 'GROUP_WRITE');
  }
}
