import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {LoggedUser, User} from '../../../models/user';
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../../services/Api/Auth/auth.service";
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss'
})
export class UserDetailsComponent implements AfterViewInit{
  @Input() user!: LoggedUser;
  ROOT = environment.apiUrl
  @Output() closeDialog = new EventEmitter<void>();
  @Output() deleteUserEvent = new EventEmitter<any>();
  CurrentLang: string = "";
  LoggedUser:any;
  deleteUser() {
    this.deleteUserEvent.emit(this.user);
    this.closeDialog.emit();
  }


  constructor(private translate: TranslateService,private authService: AuthService) {
    this.CurrentLang = this.translate.currentLang;
    this.authService.CurrentUser().then((res) => {
      this.LoggedUser = res;
    });


  }
  ngAfterViewInit(): void {
    console.log(this.user);
  }

  hasAccess(): boolean {
    return this.LoggedUser.role.role === 'ROLE_ADMIN' || this.LoggedUser.privileges.some(priv => priv.name === 'USER_WRITE');
  }
}
