import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {LoggedUser} from "../../../../models/user";
import {FormBuilder} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ReportsService} from "../../../../services/Api/Reports/reports.service";
import {ToastService} from "../../../../services/others/toast.service";

@Component({
  selector: 'view-content',
  templateUrl: './view-content.component.html',
  styleUrl: './view-content.component.scss'
})
export class ViewContentComponent implements AfterViewInit{
  @Input() ContentReport!: any;
  @Output() closeDialog = new EventEmitter<void>();
  CurrentLang:string=""
  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private service: ReportsService,
    private Toaster: ToastService
  ) {
    this.CurrentLang = translate.currentLang;
  }


  ngAfterViewInit() {

  }

  extractText(input: string): string {

    const cleanedText = input.replace(/[^a-zA-Z0-9\s]/g, '');

    return cleanedText.trim();
  }

  onCancel(): void {
    this.closeDialog.emit();
  }

}
