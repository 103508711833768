import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {
  isArabic = false


  selectedIndex = 0
  CurrentLanguage: string = "";
  constructor(private router: Router,
              private translate: TranslateService,
              private route: ActivatedRoute) {
    route.queryParams.subscribe((res) => {
      if (res['page']) {
        switch (res['page']) {
          case 'ALL_NOTIFICATIONS' :
            this.selectedIndex = 1;
            break;
          case 'UNREAD_NOTIFICATIONS' :
            this.selectedIndex = 2;
            break;
          default:
            this.selectedIndex = 1;
        }
      } else {
        this.selectedIndex = 1;
      }
    })
  }
  ngOnInit() {
    this.CurrentLanguage = this.translate.currentLang
  }
}
