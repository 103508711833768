import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DeviceUUID } from 'device-uuid';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessagingService } from '../../services/Api/Messages/messaging.service';
import { AuthService } from '../../services/Api/Auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ToastService } from '../../services/others/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../services/Api/shared/shared.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  showPassword: boolean = false;
  ipAddress: any;
  constructor(
    private fb: FormBuilder,
     private router: Router,
     private deviceService: DeviceDetectorService,
     private http: HttpClient,
     private msg: MessagingService,
     private toastService: ToastService,
     private authService: AuthService,
     private translate: TranslateService,
     private sharedService: SharedService,
     private cookieService: CookieService,
    ) {
    }

  ngOnInit(): void {
    this.Init();
    this.GetIp();
  }


  Init(){
    this.loginForm = this.fb.group({
      fullName: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
    const passwordInput = document.getElementById('password') as HTMLInputElement;
    if (this.showPassword) {
      passwordInput.type = 'text';
    } else {
      passwordInput.type = 'password';
    }
  }

  GetIp(){
    this.http.get('https://api.ipify.org/?format=json').subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      var uuid = new DeviceUUID().get();

      let login = {
        deviceId: uuid,
        deviceType:
          this.deviceService.browser +
          ' ' +
          this.deviceService.browser_version +
          '/' +
          this.deviceService.deviceType,
        ip: this.ipAddress,
        password: this.loginForm.get('password')?.value,
        tokendevice: null,
        username: this.loginForm.get('fullName')?.value
      };
      this.authService.signIn(login).then((res:any)=>{
        if(res.roles === 'ROLE_USER'){
          this.toastService.ShowToastError(this.translate.instant('LOGIN.NOT_ALLOWED'), this.translate.instant('LOGIN.LOGIN_FAILED'));
        }else{
          const today = new Date()
          const tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate() + 1)


          this.cookieService.set('TOKEN_DASH_WAYNEK', res.token!, tomorrow, '/');
          this.cookieService.set('STATE_DASH_WAYNEK', 'true', tomorrow, '/');
          this.cookieService.set('ROLE_DASH_WAYNEK', res.roles!, tomorrow, '/');

          this.sharedService.changeUser(res);
          this.router.navigate(['/home']);
          this.toastService.ShowToastSuccess(this.translate.instant('LOGIN.description'), this.translate.instant('LOGIN.title'));
        }
      })
    }else{
      this.toastService.ShowToastError(this.translate.instant('ERROR.description'), this.translate.instant('ERROR.title'));
    }
  }
}
