<div class="user-details">
  <form>
    <div class="form-grid" [class.ar]="CurrentLang === 'ar'">
      <div class="form-group">
        <label for="title">{{ 'TITLE' | translate }}</label>
        <input type="text" id="title" [value]="ContentReport.title" readonly>
      </div>

      <div class="form-group">
        <label >{{ 'CONTENTS' | translate }}</label>
        <div class="form-group-rows-conatiner">
          <div *ngFor="let contentGroup of ContentReport.contents; let i = index" class="form-group-rows">
            <input type="text" [value]="extractText(contentGroup)" readonly>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="button-group">
    <button type="button" class="action-btn br color1 bgcolor-white" (click)="onCancel()">{{ 'CANCEL' | translate }}</button>
  </div>
</div>
