<div class="members-container">
  <div class="search-bar">
    <input type="text" placeholder="{{ 'SEARCH_MEMBERS' | translate }}" [(ngModel)]="searchTerm"
           (input)="filterMembers()">
  </div>
  <div class="members-list">
    <div class="member-item" *ngFor="let member of filteredMembers">
      <img [src]="member.profileimage?ROOT+member.profileimage.url:'assets/images/waynek-1.png'" alt="{{ member.name }}"
           class="member-avatar">
      <span class="member-name">{{ member.name }}</span>
    </div>
  </div>
  <div class="w-100 text-center" *ngIf="filteredMembers.length === 0">
    {{ 'No Members Found' | translate }}
  </div>
  <div class="paginator">
    <p-paginator *ngIf="paginatedMembers.members.length > 0" [rows]="paginatedMembers.size"
                 [totalRecords]="paginatedMembers.nb_members" (onPageChange)="onPageChange($event)"></p-paginator>
  </div>
</div>
