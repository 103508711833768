<div class="chat-box" [class.ar]="currentLanguage === 'ar'">
  <div class="chat-box-header">
    <h3>{{ 'Messages'|translate }}</h3>
    <p><i class="fa fa-times"></i></p>
  </div>
  <div class="chat-box-body">
    <div *ngFor="let message of paginatedMessages.messages" class="chat-box-body-send">
      <p>{{ message.content }}</p>
      <span>{{ formatTimestamp(message.timestamp) }}</span>
    </div>
    <div *ngFor="let message of messages"
         [ngClass]="{'chat-box-body-send': message.isSent, 'chat-box-body-receive': !message.isSent}">
      <p>{{ message.content }}</p>
      <span>{{ message.timestamp }}</span>
    </div>
  </div>
  <div class="chat-box-footer" *ngIf="hasAccess()">
    <input [(ngModel)]="newMessage" placeholder="Enter Your Message" type="text" (keyup.enter)="sendMessage()"/>
    <i class="send far fa-paper-plane" (click)="sendMessage()"></i>
  </div>
</div>
<div class="chat-button" [class.ar]="currentLanguage === 'ar'"><span></span></div>
