<div class="controls">
  <div class="left-controls">
    <select class="show-select" [(ngModel)]="tableHeadersize" (change)="onSizeChange($event)">
      <option [value]="5">5</option>
      <option [value]="10">10</option>
      <option [value]="20">20</option>
    </select>
    <input type="text" [placeholder]="'SEARCH_PLACEHOLDER' | translate" [(ngModel)]="GroupName" class="user-search">
    <button class="apply-btn" [class.arabic]="isArabic" (click)="getGroups(0)">{{ 'APPLY' | translate }}</button>
  </div>
  <div class="right-controls">
    <select class="sort-select" [(ngModel)]="status">
      <option disabled>{{ 'SELECT' | translate }}</option>
      <option value="false" selected>{{ 'ACTIVE' | translate }}</option>
      <option value="true">{{ 'SUSPENDED' | translate }}</option>
    </select>
  </div>
</div>

<table class="users-table">
  <thead>
  <tr>
    <th [class.arabic]="isArabic" *ngFor="let header of tableHeaders">
      {{ header.key | translate }}
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let group of paginatedGroups.groups">
    <td><img [src]="group.profileimage?ROOT+'/'+group.profileimage.url:'assets/images/waynek-1.png'"
             [alt]="'PROFILE_IMAGE' | translate" class="profile-img"></td>
    <td>{{ group.timestamp | date }}</td>
    <td>{{ group.name }}</td>
    <td>{{ group.cretedby.username }}</td>
    <td>{{ group.memberssnumber }} <span class="eye-icon" (click)="openViewDialog('VIEW_GROUP_MEMBERS',group)"><i
      class="fa-solid fa-eye color2"></i></span></td>
    <td>{{ group.locationsnumber }}  <span class="eye-icon" (click)="openViewDialog('VIEW_GROUP_LOCATIONS',group)"><i
      class="fa-solid fa-eye color2"></i></span></td>
    <td>{{ '0' }}</td>
    <td>
          <span class="action-icon" [title]="'VIEW' | translate" (click)="openViewDialog('VIEW_GROUP',group)">
            <i class="fa-solid fa-eye t2 color2"></i>
          </span>
      <span class="action-icon" *ngIf="hasAccess()" [title]="'DELETE' | translate" (click)="Delete(group.id)"><i class="fa-solid fa-xmark t2 color1"></i></span>
    </td>
  </tr>


  <tr *ngIf="paginatedGroups.groups.length === 0">
    <td colspan="7" class="text-center">{{ 'No Data Found' | translate }}</td>
  </tr>
  </tbody>
</table>


<!--[rowsPerPageOptions]="[5, 10, 20]" -->
<div class="paginator">
  <p-paginator *ngIf="paginatedGroups.groups.length > 0" [rows]="paginatedGroups.size"
               [totalRecords]="paginatedGroups.nb_group" (onPageChange)="onPageChange($event)"></p-paginator>
</div>
