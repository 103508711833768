<div class="home-container" [ngClass]="{'reverse': currentLanguage === 'ar'}">
  <div class="home-header">
    <h1>{{ 'OVERVIEW' | translate }}</h1>
  </div>
  <div class="stats-container">
    <div *ngFor="let stat of stats" class="stat-card">
      <span class="label">{{ stat.label | translate }}</span>
      <span class="value t2">{{ stat.value }}</span>
    </div>
  </div>
  <div>
    <apx-chart
    [series]="chartOptions?.series || []"
    [chart]="chartOptions?.chart || { type: 'line' }"
    [xaxis]="chartOptions?.xaxis || {}"
    [dataLabels]="chartOptions?.dataLabels || {}"
    [grid]="chartOptions?.grid || {}"
    [stroke]="chartOptions?.stroke || {}"
    [title]="chartOptions?.title || {}">
    </apx-chart>
  </div>


  <div class="charts-container">
    <div class="left-side-chart">
      <div class="w-100 inputs-button-date">
        <input type="date" class="date-input" [(ngModel)]="startDate" (change)="onDateChange()" />
        <input type="date" class="date-input" [(ngModel)]="endDate" (change)="onDateChange()" />
      </div>
      <div>
        <apx-chart
          [series]="chartOptions2.series  || []"

          [chart]="chartOptions2.chart  || { type: 'line' }"

          [dataLabels]="chartOptions2.dataLabels || {}"

          [plotOptions]="chartOptions2.plotOptions || {}"

          [yaxis]="chartOptions2.yaxis || {}"

          [xaxis]="chartOptions2.xaxis || {}"

          [fill]="chartOptions2.fill || {}"

          [title]="chartOptions2.title || {}"

        ></apx-chart>
      </div>
    </div>
    <div class="right-side-chart">
      <Map></Map>
    </div>
  </div>
  <div class="recent-activities">
    <div class="header">
      <h2>{{ 'RECENT_ACTIVITIES' | translate }}</h2>
    </div>
    <table>
      <thead>
        <tr>
          <th [class.arabic]="isArabic" *ngFor="let header of tableHeaders">
            {{ header.key | translate }}
            <!-- <span *ngIf="header.sortable" class="sort-icon">▼</span> -->
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let action of paginatedSubAdminLast.last_actions;let i = index">
          <td [class.arabic]="isArabic">{{i + 1}}</td>
          <td [class.arabic]="isArabic">{{ action.actions?(action.actions | string):'-'}}</td>
          <td [class.arabic]="isArabic">{{ timeAgo(action.timestamp)}}</td>
          <td [class.arabic]="isArabic">{{action.user.name?action.user.name:'-'}}</td>
        </tr>
      </tbody>
    </table>

  </div>
  <div class="paginator">
    <p-paginator *ngIf="paginatedSubAdminLast.last_actions.length > 0" [rows]="paginatedSubAdminLast.size"
                 [totalRecords]="paginatedSubAdminLast.nb_last_actions" (onPageChange)="onPageChange($event)"></p-paginator>
  </div>
</div>
