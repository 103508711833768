<ngx-spinner bdColor="rgba(255, 255, 255, 0.8)" size="large" color="#31317a" type="ball-spin" [fullScreen]="true">
  <img style="margin-top: 2rem;" src="assets/images/Logo.png" alt="logo">
</ngx-spinner>
<router-outlet></router-outlet>
<app-slide-dialo></app-slide-dialo>
<app-one-way-chat *ngIf="!isRouteActive('/Sign-In') && hasAccess()"></app-one-way-chat>
<p-toast
  [showTransformOptions]="'translateY(100%)'"
  [showTransitionOptions]="'1000ms'"
  [hideTransitionOptions]="'1000ms'"
/>
