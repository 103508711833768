import { Component, OnInit, OnDestroy } from '@angular/core';
import { DialogService } from '../../services/others/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Group, User } from '../../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrl: './groups.component.scss'
})
export class GroupsComponent {
  isArabic = false


  selectedIndex = 0
  CurrentLanguage: string = "";
  constructor(private router: Router,
              private translate: TranslateService,
              private route: ActivatedRoute) {
    route.queryParams.subscribe((res) => {
      if (res['page']) {
        switch (res['page']) {
          case 'Groups' :
            this.selectedIndex = 1;
            break;
          case 'Check-ins Locations' :
            this.selectedIndex = 2;
            break;
          default:
            this.selectedIndex = 1;
        }
      } else {
        this.selectedIndex = 1;
      }
    })
  }
  ngOnInit() {
    this.CurrentLanguage = this.translate.currentLang
  }
}
