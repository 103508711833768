<div class="users-group-container">
  <div *ngFor="let group of paginator_user_group.user_group" class="group-item">
    <div class="group-info">
      <span class="group-name">{{ group.name }}</span>
<!--      <span class="visibility-icon">👁</span>-->
    </div>
    <div class="group-type t0">{{ group.cretedby.id === user.id ? ('Admin' | translate):('Member' | translate) }}</div>
  </div>
  <div class="w-100 text-center" *ngIf="paginator_user_group.user_group.length === 0">
    <span class="t1">{{ 'This user has no groups' | translate}}</span>
  </div>
</div>
