<div class="users-container" [class.arabic]="isArabic">
  <h1 class="t5">{{ 'SIDEBAR.REPORTS' | translate }}</h1>

  <div class="controls">
    <div class="left-controls">
      <select class="show-select" [(ngModel)]="tableHeadersize" (change)="onSizeChange($event)">
        <option [value]="5">5</option>
        <option [value]="10">10</option>
        <option [value]="20">20</option>
      </select>
      <input type="text" [placeholder]="'SEARCH_PLACEHOLDER' | translate" class="user-search" [(ngModel)]="Title">
      <button class="apply-btn" [class.arabic]="isArabic" (click)="getReports(0)">{{ 'APPLY' | translate }}</button>
    </div>
    <div class="right-controls">
      <select class="export-btn" (change)="onExportChange($event)">
        <option value="" disabled selected>{{ 'EXPORT' | translate }}</option>
        <option value="pdf">PDF</option>
        <option value="csv">CSV</option>
      </select>
    </div>
  </div>

  <table class="users-table" id="table-container">
    <thead>
    <tr>
      <th [class.arabic]="isArabic" *ngFor="let header of tableHeaders">
        {{ header.key | translate }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let report of PaginatedReports.reports">
      <td>{{ report.id }}</td>
      <td>{{ report.createdbyname }}</td>
      <td>{{ report.timestamp? (report.timestamp | date) : '-' }}</td>
      <td>{{ report.contenttitle }}</td>
      <td>{{ report.contentdescription }}</td>
      <td>
        <span class="action-icon t1 color1" [title]="'VIEW' | translate" (click)="openViewUserDialog(report)">
            <i class="fa-solid fa-eye"></i>
          </span>
        <span class="action-icon t1 text-danger" *ngIf="hasAccess()" (click)="Delete(report.id)" [title]="'DELETE' | translate">
            <i class="fa-solid fa-trash"></i>
          </span>
      </td>
    </tr>
    <tr *ngIf="PaginatedReports.reports.length === 0">
      <td colspan="6" class="text-center">{{ 'No Data Found' | translate }}</td>
    </tr>
    </tbody>
  </table>
</div>
