import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from '../../services/others/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Subadmin } from '../../models/user';
import {PaginatedSubAdmin, PaginatedUsers} from "../../models/paginators";
import {SubadminsService} from "../../services/Api/SubAdmins/subadmins.service";
import {NgxSpinnerService} from "ngx-spinner";
import {UserService} from "../../services/Api/user/user.service";
import {AuthService} from "../../services/Api/Auth/auth.service";

@Component({
  selector: 'app-subadmins',
  templateUrl: './subadmins.component.html',
  styleUrl: './subadmins.component.scss'
})
export class SubadminsComponent {
  isArabic = false;
  name:string="";
  tableHeadersize: number = 5;
  status: boolean = true;
  paginatedSubAdmin: PaginatedSubAdmin = { size: 5, nb_sub_admins: 0, page: -1, sub_admins: [], pages: 1 }
  LoggedUser:any;
  tableHeaders = [
    { key: 'USERNAME', sortable: true },
    { key: 'EMAIL', sortable: true },
    { key: 'PHONE_NUMBER', sortable: true },
    { key: 'CREATION_DATE', sortable: true },
    { key: 'ACTIONS', sortable: false }
  ];

  subadmins: Subadmin[] = [
    {
      name: 'SAAD',
      email: 'cantactdesign@gmail.com',
      phoneNumber: '+21563893',
      creationDate: '15/06/2023',
      role: 'Admin'
    },


  ];

  updateprev: Subscription;
  add_subadmin: Subscription;

  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
    private service: SubadminsService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router) {

    this.authService.CurrentUser().then((res) => {
      this.LoggedUser = res;
    });

    this.checkIsArabic();
    this.SUB_ADMINS(0)
    this.updateprev = this.dialogService.updateprev$.subscribe(subadmin => {
      this.SUB_ADMINS(this.paginatedSubAdmin.page)
    });
    this.add_subadmin = this.dialogService.addSubadmin$.subscribe(subadmin => {
      this.SUB_ADMINS(0)
    });
  }


  checkIsArabic() {
    this.isArabic = this.translate.currentLang === 'ar';
  }


  openViewDialog(title:string,sub: Subadmin) {
    this.dialogService.openDialog(title, sub);
  }

  SUB_ADMINS(page: number) {
    this.spinner.show()
    const size = this.paginatedSubAdmin.size || 5;
    this.service.all_sub_admins(page, size,this.name, this.status).then((res) => {
      this.spinner.hide()
      this.paginatedSubAdmin.nb_sub_admins = res.totalItems
      this.paginatedSubAdmin.page = res.currentPage
      this.paginatedSubAdmin.pages = res.totalPages
      this.paginatedSubAdmin.sub_admins = res.result
    }).finally(()=>{
      this.spinner.hide()
    })
  }

  onPageChange(event: any): void {
    this.SUB_ADMINS(event.page);
  }

  onSizeChange(event: Event): void {
    const size = +(event.target as HTMLSelectElement).value;
    this.tableHeadersize = size;
    this.paginatedSubAdmin.size = this.tableHeadersize;
  }

  Activate(id:any){
    this.userService.activateUser(id).then((res)=>{
      this.SUB_ADMINS(this.paginatedSubAdmin.page);
    })
  }

  hasAccess(): boolean {
    return this.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv?.name === 'SUB_ADMIN_WRITE');
  }
}
