<div class="dialog-overlay" [class.open]="isOpen$ | async" (click)="closeDialog()">
  <div class="dialog-content" (click)="$event.stopPropagation()">
    <div style="padding-bottom: 1vw;">
      <span class="t2" [class.ar]="CurrentLang === 'ar'">{{ (displayTitle$ | async) || '' | translate }}</span>
    </div>
    <div class="body-container">


      <ng-container *ngIf="(title$ | async) === 'View User'">
        <app-user-details [user]="content$ | async" (closeDialog)="closeDialog()"
                          (deleteUserEvent)="onDeleteUser($event)"></app-user-details>
      </ng-container>


      <ng-container *ngIf="(title$ | async) === 'Update User'">
        <app-update-user [user]="content$ | async" (closeDialog)="closeDialog()"
                         (updateuser)="onUpdateUser($event)"></app-update-user>
      </ng-container>


      <ng-container *ngIf="(title$ | async) === 'New Sub Admin'">
        <app-new-sub-admin (closeDialog)="closeDialog()"
                           (addSubadminEvent)="onAddSubadmin($event)"></app-new-sub-admin>
      </ng-container>


      <ng-container *ngIf="(title$ | async) === 'View Group Members'">
        <members [group]="content$ | async" (closeDialog)="closeDialog()"></members>
      </ng-container>


      <ng-container *ngIf="(title$ | async) === 'View Group'">
        <Group-Details [group]="content$ | async" (closeDialog)="closeDialog()" (delete_group)="onDeleteGroup($event)"
                       (suspend_group)="onSuspendGroup($event)"></Group-Details>
      </ng-container>

      <ng-container *ngIf="(title$ | async) === 'View Groups User'">
        <users-group [user]="content$ | async" (closeDialog)="closeDialog()"></users-group>
      </ng-container>


      <ng-container *ngIf="(title$ | async) === 'View Group Locations'">
        <locations [group]="content$ | async" (closeDialog)="closeDialog()"></locations>
      </ng-container>


      <ng-container *ngIf="(title$ | async) === 'View Activity Details'">
        <activity-details [activity]="content$ | async" (closeDialog)="closeDialog()"></activity-details>
      </ng-container>


      <ng-container *ngIf="(title$ | async) === 'View Privileges'">
        <privileges [sub]="content$ | async" (closeDialog)="closeDialog()" (UpdatePrev)="UpdatePrev($event)"></privileges>
      </ng-container>


      <ng-container *ngIf="(title$ | async) === 'New Content'">
        <new-content (closeDialog)="closeDialog()" (addContentEvent)="onAddContent($event)"></new-content>
      </ng-container>

      <ng-container *ngIf="(title$ | async) === 'View Content'">
        <view-content [ContentReport]="content$ | async" (closeDialog)="closeDialog()" ></view-content>
      </ng-container>

      <ng-container *ngIf="(title$ | async) === 'Edit Content'">
        <edit-content [ContentReport]="content$ | async" (closeDialog)="closeDialog()" (editContentEvent)="oneditContent($event)"></edit-content>
      </ng-container>


      <ng-container *ngIf="(title$ | async) === 'Add Description to Content'">
        <add-desc-content [ContentReport]="content$ | async" (closeDialog)="closeDialog()"(add_description_ContentEvent)="oneadd_descriptionContent($event)" ></add-desc-content>
      </ng-container>


      <ng-container *ngIf="(title$ | async) === 'Interested members'">
        <intreseted-users [event]="content$ | async" (closeDialog)="closeDialog()"></intreseted-users>
      </ng-container>


      <ng-container *ngIf="(title$ | async) === 'Check members'">
        <checked-users [event]="content$ | async" (closeDialog)="closeDialog()"></checked-users>
      </ng-container>

      <ng-container *ngIf="(title$ | async) === 'Alert'">
        <app-alert [user]="content$ | async" (closeDialog)="closeDialog()"></app-alert>
      </ng-container>

      <ng-container *ngIf="(title$ | async) === 'View Report'">
        <app-view-reports [reports]="content$ | async" (closeDialog)="closeDialog()"></app-view-reports>
      </ng-container>



    </div>
  </div>
  <div class="oval cp" (click)="closeDialog()">
    <span class="x-mark">×</span>
  </div>
</div>
