<div class="users-container" [class.arabic]="CurrentLanguage==='ar'">
    <h1 class="t5">{{ 'SIDEBAR.NOTIFICATIONS' | translate }}</h1>
    
    <div class="fxColumn fxAlignCenterCenter">
      <div class="fxRow fxAlignCenterCenter pb-3vw">
        <button mat-button class="t1 tab-item" (click)="selectedIndex=1"
                [ngClass]="{'tab-item-selected' : selectedIndex==1}" [routerLink]="[]"
                [queryParams]="{page : 'ALL_NOTIFICATIONS'}"
                queryParamsHandling="merge">{{'ALL_NOTIFICATIONS'| translate}}</button>
        <button mat-button class="t1 tab-item" (click)="selectedIndex=2"
                [ngClass]="{'tab-item-selected' : selectedIndex==2}" [routerLink]="[]"
                [queryParams]="{page : 'UNREAD_NOTIFICATIONS'}"
                queryParamsHandling="merge">{{'UNREAD_NOTIFICATIONS'| translate}}</button>
    
      </div>
    
      <div class="w-100" *ngIf="selectedIndex==1">
          <all-notification></all-notification>
      </div>
      <div class="w-100" *ngIf="selectedIndex==2">
          <unread-notification></unread-notification>
      </div>
    
    
    </div>
  </div>



  
  
  
  