
import {A11yModule} from '@angular/cdk/a11y';
import {BidiModule} from '@angular/cdk/bidi';
import {ObserversModule} from '@angular/cdk/observers';
import {OverlayModule} from '@angular/cdk/overlay';
import {PlatformModule} from '@angular/cdk/platform';
import {PortalModule} from '@angular/cdk/portal';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';


@NgModule({
  exports: [
    // CDK
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    CdkStepperModule,
    CdkTableModule,

    // Material
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    ViewContentComponent,
    EditContentComponent,
    AddDescContentComponent,
    WazeMapComponent,
    IntresetedUsersComponent,
    CheckedUsersComponent,
    StringPipe,
    AlertComponent,
    ViewReportsComponent
  ],
  imports: [
    NgForOf,
    PaginatorModule,
    ReactiveFormsModule,
    TranslateModule,
    NgIf
  ],
  declarations: [
    ViewContentComponent,
    EditContentComponent,
    AddDescContentComponent,
    WazeMapComponent,
    IntresetedUsersComponent,
    CheckedUsersComponent,
    StringPipe,
    AlertComponent,
    ViewReportsComponent,
    InputDirective
  ]
})
export class MaterialModule {}


import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Widgets/login/login.component';
import { SidebarComponent } from './includes/sidebar/sidebar.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from './includes/navbar/navbar.component';
import { UsersComponent } from './Widgets/users/users.component';
import { GroupsComponent } from './Widgets/groups/groups.component';
import { EventsComponent } from './Widgets/events/events.component';
import { SubadminsComponent } from './Widgets/subadmins/subadmins.component';
import { NotificationsComponent } from './Widgets/notifications/notifications.component';
import { ReportsComponent } from './Widgets/reports/reports.component';
import { SettingsComponent } from './Widgets/settings/settings.component';
import {AsyncPipe, CommonModule, NgForOf, NgIf} from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HomeComponent } from './Widgets/home/home.component';
import { NgxTranslateModule } from './translate/translate.module';
import { ArabiclassesDirective } from './directives/arabiclasses.directive';
import { TitleclassesDirective } from './directives/titleclasses.directive';
import { TextDirective } from './directives/text.directive';
import { HighchartsChartModule } from 'highcharts-angular';
import { MapComponent } from './includes/map/map.component';
import { MatButtonModule } from '@angular/material/button';
import { SlideDialoComponent } from './includes/slide-dialo/slide-dialo.component';
import { ButtonsDirective } from './directives/DF/buttons.directive';
import { DivsDirective } from './directives/DF/divs.directive';
import { H4sDirective } from './directives/DF/h4s.directive';
import { LabelsDirective } from './directives/DF/labels.directive';
import { OptionsDirective } from './directives/DF/options.directive';
import { SmallsDirective } from './directives/DF/smalls.directive';
import { SpansDirective } from './directives/DF/spans.directive';
import { TdsDirective } from './directives/DF/tds.directive';
import { ThsDirective } from './directives/DF/ths.directive';
import { UserDetailsComponent } from './Widgets/users/user-details/user-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertModule } from '@coreui/angular';
import { MatTabsModule } from '@angular/material/tabs';
import { GroupsListComponent } from './Widgets/groups/groups-list/groups-list.component';
import { CheckInsLocationsComponent } from './Widgets/groups/check-ins-locations/check-ins-locations.component';
import { AllNotificationComponent } from './Widgets/notifications/all-notification/all-notification.component';
import { UnreadNotificationComponent } from './Widgets/notifications/unread-notification/unread-notification.component';
import { NewSubAdminComponent } from './Widgets/subadmins/new-sub-admin/new-sub-admin.component';
import { MembersComponent } from './Widgets/groups/members/members.component';
import { UsersGroupComponent } from './Widgets/users/users-group/users-group.component';
import { LocationsComponent } from './Widgets/groups/locations/locations.component';
import { ActivityDetailsComponent } from './Widgets/groups/activity-details/activity-details.component';
import { PrevComponent } from './Widgets/subadmins/prev/prev.component';
import { OneWayChatComponent } from './includes/one-way-chat/one-way-chat.component';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { MessageService } from 'primeng/api';

import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/Api/Auth/interceptor.service';
import { PaginatorModule } from 'primeng/paginator';
import { NgxSpinnerModule } from "ngx-spinner";

import { UpdateUserComponent } from './Widgets/users/update-user/update-user.component';
import { GroupDetilsComponent } from './Widgets/groups/group-detils/group-detils.component';
import { ReportMangComponent } from './Widgets/settings/report-mang/report-mang.component';
import { NewContentComponent } from './Widgets/settings/report-mang/new-content/new-content.component';
import { AlertDialogComponent } from './Widgets/alert-dialog/alert-dialog.component';
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule} from "@angular/material/dialog";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCardModule} from "@angular/material/card";
import {MatChipsModule} from "@angular/material/chips";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatMenuModule} from "@angular/material/menu";
import {MatListModule} from "@angular/material/list";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatRadioModule} from "@angular/material/radio";
import {MatNativeDateModule, MatRippleModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSliderModule} from "@angular/material/slider";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatStepperModule} from "@angular/material/stepper";
import {MatTableModule} from "@angular/material/table";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatTooltipModule} from "@angular/material/tooltip";
import { ViewContentComponent } from './Widgets/settings/report-mang/view-content/view-content.component';
import { EditContentComponent } from './Widgets/settings/report-mang/edit-content/edit-content.component';
import { AddDescContentComponent } from './Widgets/settings/report-mang/add-desc-content/add-desc-content.component';
import {TranslateModule} from "@ngx-translate/core";
import { WazeMapComponent } from './includes/waze-map/waze-map.component';
import { IntresetedUsersComponent } from './Widgets/events/intreseted-users/intreseted-users.component';
import { CheckedUsersComponent } from './Widgets/events/checked-users/checked-users.component';
import {MessagingService} from "./services/FirebaseMsg/messaging-service";
import { StringPipe } from './PIpes/string.pipe';
import {AngularFireDatabaseModule} from "@angular/fire/compat/database";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import { AlertComponent } from './includes/alert/alert.component';
import {ToastModule} from "primeng/toast";
import {ButtonDirective} from "primeng/button";
import {Ripple} from "primeng/ripple";
import { ViewReportsComponent } from './Widgets/reports/view-reports/view-reports.component';
import { InputDirective } from './directives/DF/input.directive';
import {AutoCompleteModule} from "primeng/autocomplete";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    NavbarComponent,
    UsersComponent,
    GroupsComponent,
    EventsComponent,
    SubadminsComponent,
    NotificationsComponent,
    ReportsComponent,
    SettingsComponent,
    HomeComponent,
    ArabiclassesDirective,
    TitleclassesDirective,
    TextDirective,
    MapComponent,
    SlideDialoComponent,
    SpansDirective,
    DivsDirective,
    SmallsDirective,
    LabelsDirective,
    ButtonsDirective,
    H4sDirective,
    OptionsDirective,
    ThsDirective,
    TdsDirective,
    UserDetailsComponent,
    GroupsListComponent,
    CheckInsLocationsComponent,
    AllNotificationComponent,
    UnreadNotificationComponent,
    NewSubAdminComponent,
    MembersComponent,
    UsersGroupComponent,
    LocationsComponent,
    ActivityDetailsComponent,
    PrevComponent,
    OneWayChatComponent,
    UpdateUserComponent,
    GroupDetilsComponent,
    ReportMangComponent,
    NewContentComponent,
    AlertDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    NgApexchartsModule,
    NgxTranslateModule,
    FormsModule,
    HighchartsChartModule,
    MatButtonModule,
    BrowserAnimationsModule,
    AlertModule,
    MatTabsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    ToastrModule.forRoot(),
    PaginatorModule,
    NgxSpinnerModule,
    MaterialModule,
    ToastModule,
    ToastModule,
    ButtonDirective,
    Ripple,
    AutoCompleteModule
  ],
  exports: [
    SidebarComponent,
    NavbarComponent
  ],
  providers: [MessageService,
    MessagingService,
    AsyncPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
