import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SubadminsService} from "../../../../services/Api/SubAdmins/subadmins.service";
import {GlobalService} from "../../../../services/others/global.service";
import {ToastService} from "../../../../services/others/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {ReportsService} from "../../../../services/Api/Reports/reports.service";

@Component({
  selector: 'new-content',
  templateUrl: './new-content.component.html',
  styleUrl: './new-content.component.scss'
})
export class NewContentComponent implements OnInit {
  @Output() closeDialog = new EventEmitter<void>();
  @Output() addContentEvent = new EventEmitter<any>();
  CurrentLang: string = "";
  reportContentForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private service: ReportsService,
    private Toaster: ToastService
  ) {
    this.CurrentLang = translate.currentLang;
  }

  ngOnInit(): void {
    this.reportContentForm = this.fb.group({
      title: ['', Validators.required],
      contents: this.fb.array([this.createContent()], Validators.required)
    });
  }

  get contents(): FormArray {
    return this.reportContentForm.get('contents') as FormArray;
  }

  createContent(): FormGroup {
    return this.fb.group({
      content: ['', Validators.required]
    });
  }

  addContent(): void {
    this.contents.push(this.createContent());
  }

  removeContent(index: number): void {
    this.contents.removeAt(index);
  }

  onSubmit(): void {
    if (this.reportContentForm.valid) {
      let form = this.reportContentForm.value;
      let data = new FormData();
      let strings: any[] = [];
      data.append('title', form.title);


      form.contents.forEach((res: any) => {
        strings.push(res.content);
      });


      data.append('contents', JSON.stringify(strings));


      this.service.add_report_content(data).then((res) => {
        this.Toaster.ShowToastSuccess(this.translate.instant("MSG.CONTENT_ADDED"), this.translate.instant("MSG.SUCCESS"));
        this.addContentEvent.emit(data);
        this.closeDialog.emit();
      });
    }else{
      this.Toaster.ShowToastError(this.translate.instant("MSG.FILL_THE_FIELDS"), this.translate.instant("MSG.WARNING"));
    }
  }

  onCancel(): void {
    this.closeDialog.emit();
  }


}
