<div class="container">
  <div class="header">
    <div class="logo">
      <img src="assets/images/Logo.png" alt="Logo">
    </div>
    <div class="details">
      <p><strong>{{ 'ORGANISATION' | translate }}:</strong> {{ 'Waynek Organisation'|translate }}</p>
      <p><strong>{{ 'PROJECT' | translate }}:</strong> {{ 'Waynek Project'|translate }}</p>
      <p><strong>{{ 'TEAM' | translate }}:</strong> {{ 'Waynek Team'|translate }}</p>
    </div>
    <div class="template-info">
      <p><strong>{{ 'REPORT_ID' | translate }}:</strong> REP-{{reports.id}}</p>
      <p><strong>{{ 'FORM_CREATED' | translate }}:</strong> {{ reports.timestamp ? formatTimestamp(reports.timestamp) : '-' }}</p>
    </div>
  </div>

  <h3 class="title">{{ 'GENERAL_INFORMATION' | translate }}</h3>

  <table class="info-table">
    <tr>
      <td><strong>{{ 'COMPANY_NAME' | translate }}</strong></td>
      <td>{{ 'Waynek'|translate }}</td>
    </tr>
    <tr>
      <td><strong>{{ 'REPORT_TITLE' | translate }}</strong></td>
      <td>{{reports.contenttitle}}</td>
    </tr>
    <tr>
      <td><strong>{{ 'VIOLATOR_NAME' | translate }}</strong></td>
      <td>{{ reports.createdbyname || 'Unknown' }}</td>
    </tr>
    <tr>
      <td><strong>{{ 'DATE' | translate }}</strong></td>
      <td>{{ reports.timestamp ? formatTimestamp(reports.timestamp) : '-' }}</td>
    </tr>
  </table>

  <h3 class="title">{{ 'VIOLATION_DESCRIPTION' | translate }}</h3>

  <div class="violation-description">
    <p>{{ reports.contentdescription }}</p>
  </div>

  <div class="btns">
    <button class="add-button" (click)="printSection()">{{ 'PRINT' | translate }}</button>
  </div>
</div>
