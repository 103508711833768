import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() {
  }


  /**********USER**********/
  private userSource = new BehaviorSubject<any | null>(null);
  currentUser = this.userSource.asObservable();

  changeUser(user: any | null) {
    this.userSource.next(user)
  }

  getUser() {
    return this.userSource.getValue()
  }


  /************* Msg *****************/
  private dataSubject = new BehaviorSubject<any | null>(null);
  data$ = this.dataSubject.asObservable();

  sendData(data: any) {
    this.dataSubject.next(data);
  }

  notifyDataProcessed() {
    this.dataSubject.next(null);
  }


  /**Img**/
    // Using BehaviorSubject to store and emit the latest profile image
  private profileImageSource = new BehaviorSubject<string | null>(null);
  profileImage$ = this.profileImageSource.asObservable();

  // Method to update the profile image
  updateUserProfileImage(imageUrl: string) {
    this.profileImageSource.next(imageUrl);
  }

  /**********Stomp**********/
  private supplierStompSource = new BehaviorSubject<any|null>(null);
  currentSupplierStomp = this.supplierStompSource.asObservable();
  changeSupplierStomp(data: any) {
    this.supplierStompSource.next(data)
  }
  getSupplierStomp() {
    return this.supplierStompSource.getValue()
  }


  /**********ONway Messages**********/
  private tenderMessagesSource = new BehaviorSubject<any|null>(null);
  currentTenderMessages = this.tenderMessagesSource.asObservable();
  changeTenderMessages(data: any) {
    this.tenderMessagesSource.next(data)
  }
  getTenderMessages() {
    return this.tenderMessagesSource.getValue()
  }
}
