import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/others/language.service';
import { Router } from '@angular/router';
import { DialogService } from '../../services/others/dialog.service';
import { SharedService } from '../../services/Api/shared/shared.service';
import { AuthService } from '../../services/Api/Auth/auth.service';
import { LoggedUser } from '../../models/user';
import {environment} from "../../../environments/environment";
import {AutoCompleteCompleteEvent} from "primeng/autocomplete";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  siteLanguage = 'English';
  languageList = [
    { code: 'en', label: 'LANGUAGES.ENGLISH', flag: '🇬🇧' },
    { code: 'ar', label: 'LANGUAGES.ARABIC', flag: '🇸🇦' },
  ];
  ROOT = environment.cdn
  dropdownVisible = false;
  currentLanguage: string = "";

  LoggedUser: any;
  constructor(
    private translate: TranslateService,
    private translation: LanguageService,
    private router: Router,
    private dialogService: DialogService,
    private sharedService: SharedService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang;
    this.authService.CurrentUser().then((res) => {
      this.updateProfileImage(this.LoggedUser?.profileimage?.url);
      this.LoggedUser = res;

    });

    this.sharedService.profileImage$.subscribe((newImage) => {
      if (newImage) {
        this.updateProfileImage(newImage);
      }
    });
  }

  toggleDropdown(): void {
    this.dropdownVisible = !this.dropdownVisible;
  }


  updateProfileImage(imageUrl: any) {
    if (this.LoggedUser?.profileimage) {
      console.log(imageUrl)
      this.LoggedUser.profileimage.url = imageUrl.url;
    }
  }

  changeLanguage(localeCode: string) {
    this.translation.setLanguage(localeCode)
    window.location.reload();
  }

  isRouteActive(route: string): boolean {
    // Extract the base path from the current URL
    const currentPath = this.router.url.split('?')[0];
    return currentPath === route;
  }


  NewSubAdmin() {
    this.dialogService.openDialog('NEW_SUB_ADMIN');
  }

  NewContent() {
    this.dialogService.openDialog('NEW_CONTENT');
  }

  hasAccess(): boolean {
    return this.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv?.name === 'SUB_ADMIN_WRITE');
  }
  hasAccess_settings(): boolean {
    return this.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv?.name === 'GLOBAL_SETTINGS_WRITE');
  }


}
