import {Component, OnInit} from '@angular/core';
import {DialogService} from '../../services/others/dialog.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-slide-dialo',
  templateUrl: './slide-dialo.component.html',
  styleUrl: './slide-dialo.component.scss'
})
export class SlideDialoComponent implements OnInit {
  isOpen$!: Observable<boolean>;
  title$!: Observable<string>;
  content$!: Observable<any>;
  displayTitle$!: Observable<string>;
  CurrentLang!: string;

  constructor(private dialogService: DialogService, private translate: TranslateService) {
    this.CurrentLang = this.translate.currentLang;

  }

  ngOnInit() {
    this.Init();
  }

  closeDialog() {
    this.dialogService.closeDialog();
  }


  private getDisplayTitle(title: string): string {
    switch (title) {
      case 'View User':
        return 'User Details';
      case 'View Group Members':
        return 'Group Members';
      case 'New Sub Admin':
        return 'New Sub Admin';
      case 'View Groups User':
        return 'Groups User';
      case 'View Group Locations':
        return 'Group Locations';
      case 'View Activity Details':
        return 'Activity Details';
      case 'View Privileges':
        return 'Privileges';
      case 'Update User':
        return 'Update User';
      case 'View Group':
        return 'Group Details';
      case 'New Content':
        return 'New Report Content';
      case 'View Content':
        return 'Content Details';
      case 'Edit Content':
        return 'Edit Content';
      case 'Add Description to Content':
        return 'Add Content Description';
      case 'Interested members':
        return 'View interested members';
      case 'Check members':
        return 'View checked members';
        case 'Alert':
        return 'Alert';
        case 'View Report':
        return 'View Report';
      default:
        return title;
    }
  }


  Init() {
    this.isOpen$ = this.dialogService.isOpen$;
    this.title$ = this.dialogService.title$;
    this.content$ = this.dialogService.content$;

    this.displayTitle$ = this.title$.pipe(
      map(title => this.getDisplayTitle(title))
    );
  }

  onDeleteUser(user: any) {
    this.dialogService.deleteUser(user);
  }

  onUpdateUser(user: any) {
    this.dialogService.updateUser(user);
  }

  onDeleteGroup(group: any) {
    this.dialogService.deleteGroup(group);
  }

  onSuspendGroup(group: any) {
    this.dialogService.suspendGroup(group);
  }

  onAddSubadmin(subadmin: any) {
    this.dialogService.addSubadmin(subadmin);
  }

  onAddContent(content: any) {
    this.dialogService.addContent(content);
  }

  oneditContent(content: any) {
    this.dialogService.editContent(content);
  }

  oneadd_descriptionContent(content: any) {
    this.dialogService.adddescContent(content);
  }

  UpdatePrev(subadmin: any) {
    this.dialogService.UpdatePrev(subadmin);
  }

}
