import {Component, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {SubadminsService} from "../../../services/Api/SubAdmins/subadmins.service";
import {GlobalService} from "../../../services/others/global.service";
import {ToastService} from "../../../services/others/toast.service";

@Component({
  selector: 'app-new-sub-admin',
  templateUrl: './new-sub-admin.component.html',
  styleUrls: ['./new-sub-admin.component.scss']
})
export class NewSubAdminComponent {
  @Output() closeDialog = new EventEmitter<void>();
  @Output() addSubadminEvent = new EventEmitter<any>();
  @ViewChild('picture') picture!: ElementRef;
  subadminForm: FormGroup;
  CurrentLang: string = "";
  profile: any
  viewProfile: any

  constructor(
    private fb: FormBuilder,
    private service: SubadminsService,
    private glovar: GlobalService,
    private Toaster: ToastService,
    private translate: TranslateService
  ) {
    this.CurrentLang = this.translate.currentLang;
    this.subadminForm = this.fb.group({
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      profile: ['']
    });
  }

  onSubmit() {
    if (this.subadminForm.valid) {
      let form = this.subadminForm.value;

      const data = {
        email: form.email,
        name: form.name,
        password: form.password,
        phonenumber:form.phoneNumber,
        file:this.profile,
      }

      this.service.add_sub_admin(data).then((res)=>{
        this.Toaster.ShowToastSuccess(this.translate.instant("MSG.SUB_ADMIN_ADDED"),this.translate.instant("MSG.SUCCESS"))
        this.addSubadminEvent.emit(data);
        this.closeDialog.emit();
      }).catch((err)=>{
        if(err.status === 409){
          if(err.error === 'phone-number already exists'){
            this.Toaster.ShowToastError(this.translate.instant("MSG.PHONE_EXIST"),this.translate.instant("MSG.WARNING"))
          }
          if(err.error === 'email already exists'){
            this.Toaster.ShowToastError(this.translate.instant("MSG.PHONE_EXIST"),this.translate.instant("MSG.WARNING"))
          }
        }
      })
    }
  }

  onCancel() {
    this.closeDialog.emit();
  }




  // getProfileImage(e: any) {
  //   if (e.target.files.length > 0) {
  //     this.profile = e.target.files[0];
  //     this.glovar
  //       .getImg(this.profile)
  //       .then((result) => (this.viewProfile = result));
  //   } else {
  //     this.Toaster.ShowToastError(this.translate.instant("MSG.ADD_PHOTO"),this.translate.instant("MSG.WARNING"));
  //   }
  // }

}
