import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

  token: string = '';

  constructor(private authService: AuthService, private router: Router) {
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.token = this.authService.getToken();
    let url = req.url
    let ispublic = url.includes(environment.apiUrl + '/api/v1/public/')
    let other = !url.includes(environment.apiUrl)
    if (!ispublic && !other) {
      if (this.token && this.token.length > 0) {
        const tokenizedReq = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + this.token)});
        return next.handle(tokenizedReq).pipe(
          tap({
            error: (err) => {
              if (err instanceof HttpErrorResponse) {
                // this.glovar.handleHttpError(err)
              }
            }
          }));
      } else {
        let isauth = url.includes(environment.apiUrl + '/api/v1/auth/current_user')
        if (!isauth) {
          next.handle(req).pipe(
            tap({
                error: (err) => {
                  if (err instanceof HttpErrorResponse) {
                    // this.glovar.handleHttpError(err)
                  }
                }
              }
            ))
        } else {
          // this.glovar.showMsg('isauth = false')
          this.router.navigate(['/Sign-In']);
          return next.handle(req);
        }
      }
    }
    return next.handle(req).pipe(
      tap({
          error: (err) => {
            if (err instanceof HttpErrorResponse) {
              // this.glovar.handleHttpError(err)
            }
          }
        }
      ))
  }
}