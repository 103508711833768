import {Component} from '@angular/core';
import {PaginatedContentReports} from "../../../models/paginators";
import {Subscription} from "rxjs";
import {DialogService} from "../../../services/others/dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

import {NgxSpinnerService} from "ngx-spinner";

import {ReportsService} from "../../../services/Api/Reports/reports.service";
import {MatDialog} from "@angular/material/dialog";
import {ToasterService} from "@coreui/angular";
import {ToastService} from "../../../services/others/toast.service";
import {AuthService} from "../../../services/Api/Auth/auth.service";


@Component({
  selector: 'app-report-mang',
  templateUrl: './report-mang.component.html',
  styleUrl: './report-mang.component.scss'
})
export class ReportMangComponent {
  isArabic = false;
  PaginatedContentReports: PaginatedContentReports = {size: 5, nb_content: 0, page: -1, content: [], pages: 1}
  tableHeadersize: number = 5;
  tableHeaders = [
    {key: '#', sortable: false},
    {key: 'TITLE', sortable: true},
    {key: 'CONTENTS', sortable: true},
    {key: 'ACTIONS', sortable: false}
  ];
  title: string = ""
  LoggedUser:any
  added_content_Subscription: Subscription;
  update_content_Subscription: Subscription;
  add_description_Subscription: Subscription;

  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
    private router: Router,
    private Toaster: ToastService,
    private service: ReportsService,
    private dialog: MatDialog,
    private authService: AuthService,
    private spinner: NgxSpinnerService,) {
    this.authService.CurrentUser().then((res) => {
      this.LoggedUser = res;
    });
    this.checkIsArabic();
    this.added_content_Subscription = this.dialogService.addContent$.subscribe(user => {
      this.GET_CONTENT(0);
    });
    this.update_content_Subscription = this.dialogService.editContent$.subscribe(user => {
      this.GET_CONTENT(0);
    });
    this.add_description_Subscription = this.dialogService.adddrscContent$.subscribe(user => {
      this.GET_CONTENT(0);
    });
    this.GET_CONTENT(0);
  }


  openViewDialog(title: any, content: any) {
    this.dialogService.openDialog(title, content);
  }

  checkIsArabic() {
    this.isArabic = this.translate.currentLang === 'ar';
  }

  GET_CONTENT(page: any) {
    this.spinner.show()
    const size = this.PaginatedContentReports.size || 5;
    this.service.contents_reports(page, size, this.title).then((res) => {
      this.spinner.hide()
      this.PaginatedContentReports.nb_content = res.totalItems
      this.PaginatedContentReports.page = res.currentPage
      this.PaginatedContentReports.pages = res.totalPages
      this.PaginatedContentReports.content = res.result
    }).finally(() => {
      this.spinner.hide()
    })
  }

  onSizeChange(event: Event): void {
    const size = +(event.target as HTMLSelectElement).value;
    this.tableHeadersize = size;
    this.PaginatedContentReports.size = this.tableHeadersize;
  }

  onPageChange(event: any): void {
    this.GET_CONTENT(event.page);
  }

  extractText(input: string): string {

    const cleanedText = input.replace(/[^a-zA-Z0-9\s]/g, '');

    return cleanedText.trim();
  }


  Delete(id: any) {
    if (confirm("Are you sure to delete ?")) {
      this.service.delete_content(id).then((res) => {
        this.Toaster.ShowToastSuccess(this.translate.instant("MSG.CONTENT_DELETED"), this.translate.instant("MSG.SUCCESS"));
      }).finally(() => {
        this.GET_CONTENT(0)
      })
    }
  }

  hasAccess_settings(): boolean {
    return this.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv?.name === 'GLOBAL_SETTINGS_WRITE');
  }
}
