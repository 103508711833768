import {Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as L from 'leaflet';
interface Activity {
  name: string;
  radius: string;
  group: string;
  time: string;
  address: string;
  city: string;
}

@Component({
  selector: 'activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss']
})
export class ActivityDetailsComponent implements OnInit, AfterViewInit {
  @Input() activity: any;
  @Output() closeDialog = new EventEmitter<void>();
  currentLang: string = '';
  activityForm: FormGroup;



  constructor(
    private translate: TranslateService,
    private fb: FormBuilder
  ) {
    this.currentLang = this.translate.currentLang;
    this.activityForm = this.fb.group({
      username: [''],
      checkdiameter: [''],
      groupname: [''],
      location: [''],
    });
  }

  ngOnInit() {
    if (this.activity) {
      this.activityForm.patchValue(this.activity);
    }
  }

  ngAfterViewInit() {

  }

  onClose() {
    this.closeDialog.emit();
  }

  formatTimestampToTime(timestamp: number): string {
    // Create a Date object from the timestamp
    const date = new Date(timestamp);

    // Extract hours, minutes, and seconds
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Format the time as HH:mm:ss
    return `${hours}:${minutes}:${seconds}`;
  }
}
