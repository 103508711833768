<div class="settings-container" [class.arabic]="isArabic">
  <h1>{{ 'SETTINGS.GENERAL' | translate }}</h1>

    <div class="profile-section">
      <div>
        <div (click)="picture_file.click()" class="w-5rem h-5rem br50 br"  style="background-size: cover;" *ngIf="!profile">
          <img [src]="ROOT+'/'+LoggedUser?.profileimage?.url" style="width:100%;height:100%;object-fit: cover">
        </div>
        <div (click)="picture_file.click()" class="w-5rem h-5rem br50" style="background-size: cover;" *ngIf="profile"
             [style.background-image]="'url(' +viewProfile+')'"></div>
      </div>
    </div>
    <input type="file" #picture_file hidden accept="image/png"
           (change)="getProfileImage($event)">


  <h4>{{ 'SETTINGS.ACCOUNT_INFO' | translate }}</h4>
  <div class="input-group">
    <input type="email" [(ngModel)]="email" readonly>
  </div>
  <div class="input-group2">
    <input type="password" [(ngModel)]="password" [placeholder]="'WRITE_OLD_PASSWORD'|translate" [disabled]="!hasAccess()">
    <input type="password" [(ngModel)]="password2" [placeholder]="'REWRITE_PASSWORD'|translate" [disabled]="!hasAccess()">
    <button class="edit-button cp" (click)="update_pass()" [disabled]="!hasAccess()">{{ 'UPDATE' | translate }}</button>
  </div>
  <h4>{{ 'SETTINGS.ACTIONS' | translate }}</h4>
  <div class="fxRow fxAlignBetweenCenter pb-1vw">
    <span class="t0">{{'SETTINGS.RCM' | translate}}</span>
    <div class="arrow-container cp" [routerLink]="'/settings/Report/Manage'">
      <i class="fa-solid fa-arrow-right"></i>
    </div>
  </div>
  <h4>{{ 'SETTINGS.LANGUAGE' | translate }}</h4>
  <select [(ngModel)]="language" (change)="change(language)" [disabled]="!hasAccess()">
    <option value="en">{{ 'SETTINGS.ENGLISH' | translate }}</option>
    <option value="ar">{{ 'SETTINGS.ARABIC' | translate }}</option>
  </select>

  <h4>{{ 'SETTINGS.NOTIFICATIONS' | translate }}</h4>
<!--  <div class="toggle-group">-->
<!--    <label>-->
<!--      {{ 'SETTINGS.IMPORTANT_UPDATES' | translate }}-->
<!--      <input type="checkbox" [(ngModel)]="importantUpdates">-->
<!--      <span class="toggle"></span>-->
<!--    </label>-->
<!--  </div>-->
  <div class="toggle-group">
    <label>
      {{ 'SETTINGS.CHECKINS_LOCATIONS' | translate }}
      <input type="checkbox" [(ngModel)]="checkInsAndLocations" (change)="onCheckInsChange($event)">
      <span class="toggle"></span>
    </label>
  </div>
  <div class="toggle-group">
    <label>
      {{ 'SETTINGS.ALLOW_RESET_PASSWORD' | translate }}
      <input type="checkbox" [(ngModel)]="allowResetPassword">
      <span class="toggle"></span>
    </label>
  </div>
  <p class="note">{{ 'SETTINGS.RESET_PASSWORD_NOTE' | translate }}</p>

  <div class="button-group">
    <button class="save-button" (click)="onSave()" *ngIf="hasAccess()">{{ 'SAVE' | translate }}</button>
    <button class="save-button" (click)="updatephoto()" *ngIf="hasAccess()">{{ 'UPDATE_PHOTO' | translate }}</button>
  </div>



</div>
