<div class="controls">
  <div class="left-controls w-100">
    <div>
      <select class="show-select" [(ngModel)]="tableHeadersize" (change)="onSizeChange($event)">
        <option [value]="5">5</option>
        <option [value]="10">10</option>
        <option [value]="20">20</option>
      </select>
      <button class="apply-btn" [class.arabic]="isArabic" (click)="all_notification(0)">{{ 'APPLY' | translate }}</button>
    </div>
    <div class="notif-item select-all" *ngIf="hasAccess()">
      <label>
        <input type="checkbox" id="selectAll" (change)="toggleSelectAll($event)">
        Select all
      </label>
      <button class="apply-btn"  (click)="markSelectedAsRead()">{{ 'MARK_AS_READ' | translate }}</button>
    </div>
  </div>
</div>

<div class="notification-container">
  <div class="notification-item" [class.waiting-bg]="notif.status==='WAITING'" [class.read-bg]="notif.status!='WAITING'"
       *ngFor="let notif of PaginatedContentNotification.notification">
    <div class="notif-item" *ngIf="hasAccess()">
      <input type="checkbox" class="notification-checkbox" [(ngModel)]="notif.selected" (change)="updateSelection(notif)">
    </div>
    <div class="notification-content">
      <span class="notification-text t1">{{ notif.subject | string }}</span>
      <span class="notification-text t0">{{ notif.content }}</span>
    </div>
    <span class="notification-time">{{ notif.timestmp ? (notif.timestmp | date: 'dd.MM.yyyy HH:mm') : '-' }}</span>
  </div>
  <div *ngIf="PaginatedContentNotification.notification.length === 0" class="w-100 text-center">
    <span class=" w-100">{{ 'No Data Found' | translate }}</span>
  </div>
  <div class="paginator">
    <p-paginator *ngIf="PaginatedContentNotification.notification.length > 0" [rows]="PaginatedContentNotification.size"
                 [totalRecords]="PaginatedContentNotification.nb_notification" (onPageChange)="onPageChange($event)"></p-paginator>
  </div>
</div>
