import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private http: HttpClient,
  ) { }


  all_notifications(page: number,size: number) {
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size)

    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/notifications/all_my_notification`, { params: httpParams }));
  }

  all_waiting_notifications(page: number,size: number) {
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size)

    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/notifications/all_my_notification_waiting`, { params: httpParams }));
  }


  read_my_notification(notificationIds: number[]): Promise<any> {
    const idListParam = notificationIds.join(',');
    const params = new HttpParams().set('notificationid', idListParam);

    const url = `${environment.apiUrl}/api/v1/notifications/read_my_waiting_notification`;

    return lastValueFrom(
      this.http.patch<any>(url, null, { params })
    ).catch(error => {
      console.error('Error marking notifications as read:', error);
      throw error; // Re-throw error if necessary
    });
  }

}
