import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private localStorageKey = 'lang';

  constructor(private translate: TranslateService, private http: HttpClient) {
    this.initTranslate();
  }

  initTranslate() {
    this.translate.addLangs(['en', 'ar']);
    const savedLanguage = localStorage.getItem(this.localStorageKey);
    this.translate.setDefaultLang(savedLanguage?savedLanguage:'en');

    const browserLang = this.translate.getBrowserLang();
    if (savedLanguage) {
      this.translate.use(savedLanguage);
    } else {
      this.translate.use(browserLang && browserLang.match(/en|ar/) ? browserLang : 'en');
    }
  }
  get LangChange(){
    return this.translate.onLangChange;
  }
  loadTranslations(): Observable<any> {
    return this.http.get(`assets/i18n/${this.currentLang}.json`);
  }
  getLanguageDirection(): string {
    return this.currentLang === 'ar' ? 'rtl' : 'ltr';
  }
  setLanguage(lang: string): void {

    localStorage.setItem(this.localStorageKey, lang);
    this.translate.use(lang);

  }
  setLanguageAttributes() {
    const lang = this.currentLang  || 'en';
    document.documentElement.lang = lang;
    document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';
    document.body.style.textAlign = lang === 'ar' ? 'right' : 'left';

  }
  get currentLang(){
    return this.translate.currentLang
  }
  currentLangForExtrnal(){
    return this.translate.currentLang
  }
}
