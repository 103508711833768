import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaginatedContentNotification } from "../../../models/paginators";
import { NotificationsService } from "../../../services/Api/notifications/notifications.service";
import { NgxSpinnerService } from "ngx-spinner";
import {AuthService} from "../../../services/Api/Auth/auth.service";

@Component({
  selector: 'all-notification',
  templateUrl: './all-notification.component.html',
  styleUrls: ['./all-notification.component.scss']
})
export class AllNotificationComponent {
  isArabic = false;
  tableHeadersize: number = 5;
  PaginatedContentNotification: PaginatedContentNotification = { size: 5, nb_notification: 0, page: -1, notification: [], pages: 1 };
  LoggedUser:any;
  constructor(
    private translate: TranslateService,
    private service: NotificationsService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
  ) {
    this.authService.CurrentUser().then((res) => {
      this.LoggedUser = res;
    });
    this.checkIsArabic();
    this.all_notification(0);
  }

  checkIsArabic() {
    this.isArabic = this.translate.currentLang === 'ar';
  }

  all_notification(page: any) {
    this.spinner.show();
    const size = this.PaginatedContentNotification.size || 5;
    this.service.all_notifications(page, size).then((res) => {
      this.spinner.hide();
      this.PaginatedContentNotification.nb_notification = res.totalItems;
      this.PaginatedContentNotification.page = res.currentPage;
      this.PaginatedContentNotification.pages = res.totalPages;
      this.PaginatedContentNotification.notification = res.result;
    }).finally(() => {
      this.spinner.hide();
    });
  }

  onSizeChange(event: Event): void {
    const size = +(event.target as HTMLSelectElement).value;
    this.tableHeadersize = size;
    this.PaginatedContentNotification.size = this.tableHeadersize;
    this.all_notification(0); // Reload notifications with new size
  }

  markSelectedAsRead() {
    const selectedIds = this.PaginatedContentNotification.notification
      .filter(notif => notif.selected)
      .map(notif => notif.id);

    if (selectedIds.length > 0) {
      this.service.read_my_notification(selectedIds).then(() => {
        this.all_notification(0); // Reload notifications after marking as read
      });
    }
  }

  toggleSelectAll(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.PaginatedContentNotification.notification.forEach(notif => {
      notif.selected = isChecked;
    });
  }

  updateSelection(notif: any) {
    if (notif.selected) {
      // Logic to handle the selected state, if needed
    } else {
      // Logic to handle the deselected state, if needed
    }
  }

  hasAccess(): boolean {
    return this.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv?.name === 'NOTIFICATION_WRITE');
  }

  onPageChange(event: any): void {
    this.all_notification(event.page);
  }
}
