import {Component, OnInit, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {Group, Groups} from '../../../models/user';
import {DialogService} from '../../../services/others/dialog.service';
import {UserService} from "../../../services/Api/user/user.service";
import {NgxSpinnerService} from "ngx-spinner";
import {GroupsService} from "../../../services/Api/Groups/groups.service";
import {PaginatedGroups, PaginatedUsers} from "../../../models/paginators";
import {ToastService} from "../../../services/others/toast.service";
import {AuthService} from "../../../services/Api/Auth/auth.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'groups',
  templateUrl: './groups-list.component.html',
  styleUrl: './groups-list.component.scss'
})
export class GroupsListComponent {
  isArabic = false;
  GroupName:string = "";
  tableHeadersize: number = 5;
  status:boolean = false;
  message: string | null = null;
  groups: Groups[] = []
  paginatedGroups: PaginatedGroups = {size: 5, nb_group: 0, page: -1, groups: [], pages: 1}
  LoggedUser:any;
  ROOT = environment.cdn
  tableHeaders = [
    {key: 'PHOTO', sortable: false},
    {key: 'CREATION_DATE', sortable: false},
    {key: 'GROUP_NAME', sortable: true},
    {key: 'CREATOR', sortable: true},
    {key: 'NUMBER_OF_MEMBER', sortable: true},
    {key: 'NUMBER_OF_LOCATIONS', sortable: true},
    {key: 'EVENTS', sortable: true},
    {key: 'ACTIONS', sortable: false}
  ];

  groupss:Group[] = [
    {
      creationDate: '05/12/2025',
      groupName: 'work team',
      creator: 'Sarrah',
      phoneNumber: '55800213',
      numberOfMembers: 10,
      numberOfLocations: 5,
      events: 0
    },
  ];

  private deleteGroupSubscription: Subscription;
  private suspendGroupSubscription: Subscription;

  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
    private router: Router,
    private Toaster: ToastService,
    private authService: AuthService,
    private groupservice: GroupsService,
    private spinner: NgxSpinnerService) {
    this.checkIsArabic();
    this.getGroups(0)
    this.deleteGroupSubscription = this.dialogService.deleteGroup$.subscribe(group => {
      this.getGroups(0)
    });

    this.suspendGroupSubscription = this.dialogService.suspendGroup$.subscribe(group => {
      this.getGroups(0)
    });

    this.authService.CurrentUser().then((res) => {
      this.LoggedUser = res;
    });

  }


  getGroups(page: number) {
    this.spinner.show()
    const size = this.paginatedGroups.size || 5;
    this.groupservice.getGroups(page, size,this.GroupName,this.status).then((res) => {
      this.spinner.hide()
      this.paginatedGroups.nb_group = res.totalItems
      this.paginatedGroups.page = res.currentPage
      this.paginatedGroups.pages = res.totalPages
      this.paginatedGroups.groups = res.result
    }).finally(() => {
      this.spinner.hide()
    })
  }


  onPageChange(event: any): void {
    this.getGroups(event.page);
  }


  checkIsArabic() {
    this.isArabic = this.translate.currentLang === 'ar';
  }

  openViewDialog(title: string, group: Groups) {
    this.dialogService.openDialog(title, group);
  }

  onSizeChange(event: Event): void {
    const size = +(event.target as HTMLSelectElement).value;
    this.tableHeadersize = size;
    this.paginatedGroups.size = this.tableHeadersize;
  }



  Delete(id: any) {
    if (confirm("Are you sure to delete ?")) {
      this.groupservice.delete_group(id).then((res) => {
        this.Toaster.ShowToastSuccess(this.translate.instant('MSG.GROUP_DELETED'), this.translate.instant('MSG.SUCCESS'));
      }).finally(() => {
        this.getGroups(0)
      })
    }
  }

  hasAccess(): boolean {
    return this?.LoggedUser?.role?.role === 'ROLE_ADMIN' || this.LoggedUser?.privileges.some(priv => priv?.name === 'GROUP_WRITE');
  }
}


