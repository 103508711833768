import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../../../environments/environment";
import { lastValueFrom } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token?: string | null;
  constructor(
    private http: HttpClient,
    private cookieService: CookieService
    ) { }


  signIn(user: any) {
    return lastValueFrom(this.http.post<any>(`${environment.apiUrl}/api/v1/auth/signin`, user));
  }

  CurrentUser() {
    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/auth/current_user`));
  }


  getToken() {
    this.token = this.cookieService.get('TOKEN_DASH_WAYNEK');
    return this.token;
  }

  reset_pass(data: any) {
    const params = new HttpParams()
      .set('oldpassword', data.oldpassword)
      .set('password', data.password);

    return lastValueFrom(
      this.http.patch<any>(`${environment.apiUrl}/api/v1/users/update_password`, null, { params })
    );
  }

  logoutUser(data: any) {
    return lastValueFrom(
      this.http.put(environment.apiUrl + `/api/v1/auth/logout`, data, { responseType: 'text' })
    );
  }
}
