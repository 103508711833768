import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private http: HttpClient,
  ) { }

  getEvents(page: number,size: number,name?:string,groupname?:string,archive?:boolean) {
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size)

    if (name) httpParams = httpParams.append('name', name);
    if (groupname) httpParams = httpParams.append('groupname', groupname);
    if (archive) httpParams = httpParams.append('archive', archive);

    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/event/all_events`, { params: httpParams }));
  }

  Delete_Event(event_id:any){
    return lastValueFrom(this.http.patch<any>(`${environment.apiUrl}/api/v1/event/delete_event/${event_id}`, {}));
  }

  Checked_MEM(page: number,size: number,event_id:any){
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size)
    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/event/event_checked_users/${event_id}`, { params: httpParams }));
  }


  INTER_MEM(page: number,size: number,event_id:any){
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size)
    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/event/event_interested_users/${event_id}`, { params: httpParams }));
  }
}
