<div class="main-container" [ngClass]="{'row-reverse': currentLanguage === 'ar'}">
    <div class="search">
        <i class="fa-solid fa-magnifying-glass" [ngClass]="{'to-right': currentLanguage === 'ar'}"></i>
        <input type="search" class="t0" placeholder="{{ 'SEARCH_PLACEHOLDER' | translate }}" [ngClass]="{'rtl': currentLanguage === 'ar'}"/>
    </div>
    <div class="end-side"  [ngClass]="{'row-reverse': currentLanguage === 'ar'}">
        <div class="icons">
            <i class="fa-solid fa-globe t1" title="{{ 'GLOBE_ICON' | translate }}" (click)="toggleDropdown()"></i>
            <div *ngIf="dropdownVisible" class="dropdown-menu" [ngClass]="{'to-right': currentLanguage === 'ar'}">
                <ul>
                    <li *ngFor="let language of languageList" (click)="changeLanguage(language.code)" [ngClass]="{'reverse': currentLanguage === 'ar'}">
                        {{ language.flag }} {{ language.label | translate }}
                    </li>
                </ul>
            </div>
<!--            <i class="fa-regular fa-bell t1" title="{{ 'BELL_ICON' | translate }}"></i>-->
<!--            <i class="fa-solid fa-comments t1" title="{{ 'COMMENTS_ICON' | translate }}"></i>-->
<!--            <i class="fa-solid fa-headset t1" title="{{ 'HEADSET_ICON' | translate }}"></i>-->
            <button class="btn bgcolor1 cp" (click)="NewSubAdmin()" *ngIf="isRouteActive('/manage-subadmins') && hasAccess()">{{ 'NEW_SUB_ADMIN' | translate }}</button>
            <button class="btn bgcolor1 cp" (click)="NewContent()" *ngIf="isRouteActive('/settings/Report/Manage') && hasAccess_settings()">{{ 'NEW_CONTENT' | translate }}</button>
        </div>
        <span>{{ LoggedUser?.username }}</span>
        <div class="img-box">
          <img [src]="ROOT+'/'+LoggedUser?.profileimage?.url" style="width:100%;height:100%;object-fit: cover">
        </div>
    </div>
</div>
