import {Component, ViewChild, OnInit, Inject, NgZone, PLATFORM_ID, OnDestroy} from '@angular/core';
import { ChartOptions, ChartOptions2 } from '../../models/charts';
import { ChartComponent } from 'ng-apexcharts';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {StatService} from "../../services/Api/stat/stat.service";
import {SubAdminsLastActionsService} from "../../services/Api/SubAdmins/sub-admins-last-actions.service";
import {PaginatedSubAdmin, paginatedSubAdminLast} from "../../models/paginators";
import {NgxSpinner, NgxSpinnerModule, NgxSpinnerService} from "ngx-spinner";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit,OnDestroy {
  currentLanguage: string;
  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;
  public chartOptions2!: Partial<ChartOptions2>;
  public stats: { label: string, value: string }[];
  startDate: string = '';
  endDate: string = '';
  isArabic = false
  tableHeaders = [
    {key: '#', sortable: false},
    {key: 'ACTIONS', sortable: true},
    {key: 'ELAPSED_TIME', sortable: true},
    {key: 'ByWho', sortable: true},
  ];
  private map!: am4charts.XYChart;

  constructor(private spinner: NgxSpinnerService,private LastActionsService: SubAdminsLastActionsService,private statService: StatService,private router: Router, private translate: TranslateService, @Inject(PLATFORM_ID) private platformId: Object, private zone: NgZone) {
    // this.startDate = this.formatDate('2024-01-01')
    // this.endDate = this.formatDate('2024-01-01')
    // this.statService.getNewUsersStartEndDate(this.startDate, this.endDate).then((data) => {
    //   this.updateChartOptions2(data);
    // });
    this.currentLanguage = this.translate.currentLang;
    this.stats = [

    ];
  }

  ngOnInit() {
    this.spinner.show()

    this.checkIsArabic();
    this.updateChartOptions();
    this.updateChartOptions2();
    this.translate.onLangChange.subscribe(() => {
      this.currentLanguage = this.translate.currentLang;
      this.updateChartOptions();
      this.updateChartOptions2();
    });
    this.fetchStatsData();
    this.Load(0)
    this.spinner.hide()
  }


  onDateChange() {
    console.log(this.startDate)
    if (this.startDate && this.endDate) {
      const formattedStartDate = this.formatDate(this.startDate);
      const formattedEndDate = this.formatDate(this.endDate);
      this.statService.getNewUsersStartEndDate(formattedStartDate, formattedEndDate).then((data) => {
        this.updateChartOptions2(data);
      });
    }
  }

  async fetchStatsData() {
    try {
      const checkinsCount = await this.statService.getCheckinsCount();
      const eventsCount = await this.statService.getEventsCount();
      const groupsCount = await this.statService.getGroupsCount();
      const usersCount = await this.statService.getUsersCount();

      this.stats = [
        { label: this.translate.instant('STATS.CHECKINS_TODAY'), value: checkinsCount.toString() },
        { label: this.translate.instant('STATS.TOTAL_EVENTS'), value: eventsCount.toString() },
        { label: this.translate.instant('STATS.TOTAL_GROUPS'), value: groupsCount.toString() },
        { label: this.translate.instant('STATS.TOTAL_USERS'), value: usersCount.toString() }
      ];
    } catch (error) {
      console.error('Error fetching stats data:', error);
    }
  }

  updateChartOptions() {
    this.statService.getUsersEvolution().then((res) => {

      const categories = res.map((item: any) => item.mounthname);
      const seriesData = res.map((item: any) => item.newuserscount);
      const activeuserData = res.map((item: any) => item.activeuserscount);

      this.chartOptions = {
        series: [
          {
            name: this.translate.instant("CHART.LABEL_NEW_USERS"),
            data: seriesData,
          },
          {
            name: this.translate.instant("CHART.LABEL_ACTIVE_USERS"),
            data: activeuserData,
          }
        ],

        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },

        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        title: {
          text: this.translate.instant("CHART.TITLE"),
          align: this.currentLanguage === 'ar' ? "center" : "left",
          style: {
            fontFamily: this.currentLanguage === 'ar' ? 'Cairo-Medium' : 'Poppins-Medium',
            fontWeight: 'bold',
          }
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: categories,
          labels: {
            style: {
              cssClass: this.currentLanguage === 'ar' ? 'CM' : 'PM' // Apply the class to the categories
            }
          }
        }
      };
    });
  }


  updateChartOptions2(data?: any) {
    const categories = data ? data.map((item: any) => Object.keys(item)[0]) : [];
    const values = data ? data.map((item: any) => Object.values(item)[0]) : [];

    this.chartOptions2 = {
      series: [
        {
          name: this.translate.instant("CHART2.LABEL"),
          data: values
        }
      ],
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return val + "";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      xaxis: {
        categories: categories,
        labels: {
          style: {
            cssClass: this.currentLanguage === 'ar' ? 'CM' : 'PM' // Apply the class to the categories
          }
        },
        position: "bottom",
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5
            }
          }
        },
        tooltip: {
          enabled: false,
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100]
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          formatter: function(val) {
            return val + "";
          }
        }
      },
      title: {
        text: this.translate.instant("CHART2.TITLE"),
        floating: false,
        align: this.currentLanguage === 'ar' ? "center" : "left",
        style: {
          color: "#444",
          fontFamily: this.currentLanguage === 'ar' ? 'Cairo-Medium' : 'Poppins-Medium',
          fontWeight: 'bold',
        }
      }
    };
  }


  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit() {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);

      let chart = am4core.create("chartdiv", am4charts.XYChart);

      chart.paddingRight = 20;

      let data:any[] = [];
      let visits = 10;
      for (let i = 1; i < 366; i++) {
        visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
        data.push({ date: new Date(2018, 0, i), name: "name" + i, value: visits });
      }

      chart.data = data;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      if (valueAxis.tooltip) {
        valueAxis.tooltip.disabled = true;
      }
      valueAxis.renderer.minWidth = 35;

      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";
      series.tooltipText = "{valueY.value}";

      chart.cursor = new am4charts.XYCursor();

      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;

      this.map = chart;
    });
  }


  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.chart) {
        this.map.dispose();
      }
    });
  }

  formatDate(date: string): string {
    // Convert date from YYYY-MM-DD to DD-MM-YYYY format
    const [year, month, day] = date.split('-');

    return `${year}-${month}-${day}`;
  }

  paginatedSubAdminLast: paginatedSubAdminLast = { size: 5, nb_last_actions: 0, page: -1, last_actions: [], pages: 1 }
  Load(page:any){
    this.LastActionsService.all_actions(page,this.paginatedSubAdminLast.size).then((res)=>{
      this.paginatedSubAdminLast.nb_last_actions = res.totalItems
      this.paginatedSubAdminLast.page = res.currentPage
      this.paginatedSubAdminLast.pages = res.totalPages
      this.paginatedSubAdminLast.last_actions = res.result
    })
  }

  timeAgo(timestamp: number): string {
    const now = Date.now();
    const diffInMs = now - timestamp;

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return years === 1 ? '1 year ago' : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? '1 month ago' : `${months} months ago`;
    } else if (weeks > 0) {
      return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
    } else if (days > 0) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else {
      return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
    }
  }
  onPageChange(event: any): void {
    this.Load(event.page);
  }

  checkIsArabic() {
    this.isArabic = this.translate.currentLang === 'ar';
  }
}
