import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(
    private http: HttpClient,
  ) { }

  UpdateImg(userId: any, file: any) {
    if (file === null || file === undefined)
      return null;
    let data = new FormData();
    data.append('file', file)
    return lastValueFrom(this.http.patch<any>(`${environment.apiUrl}/api/v1/media/update_user_profile_image/${userId}`,data));
  }
}
