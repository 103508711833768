<div class="main-container" [ngClass]="{'row-reverse': currentLanguage === 'ar'}">
    <div class="sidebar pr" [ngClass]="{'reverse': currentLanguage === 'ar'}">
        <div class="img-container">
            <img src="assets/images/Logo.svg" alt="logo">
        </div>
        <div class="sidebar-items">
            <div *ngFor="let item of sidebarItems" class="fxRow-nw item fxAlignStartCenter cp"
                 [class.active]="isRouteActive(item.route) && currentLanguage != 'ar'"
                 [class.active-ar]="isRouteActive(item.route) && currentLanguage === 'ar'"
                 (click)="item.label==='SIDEBAR.LOGOUT'?Logout():navigateTo(item.route,item.title)" [class.barred]="(item.label!='SIDEBAR.DASHBOARD'&&item.label!='SIDEBAR.LOGOUT')?!hasAccess(item.title):''">
                <i class="fa-solid" [ngClass]="item.icon + ' plr-1vw t1'"></i>
                <span class="t0" >{{ item.label | translate }}</span>
            </div>
        </div>
    </div>
    <div class="content">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
    </div>
<!--    <div>-->
<!--        <app-one-way-chat></app-one-way-chat>-->
<!--    </div>-->
</div>
