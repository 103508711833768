import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private http: HttpClient,
  ) { }

  all_messages(page: number,size: number) {
    let httpParams = new HttpParams()
      .append('page', page)
      .append('size', size)
    return lastValueFrom(this.http.get<any>(`${environment.apiUrl}/api/v1/message/admin_broadcast_messages`, { params: httpParams }));
  }
}
