import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ReportsService} from "../../../../services/Api/Reports/reports.service";
import {ToastService} from "../../../../services/others/toast.service";

@Component({
  selector: 'edit-content',
  templateUrl: './edit-content.component.html',
  styleUrl: './edit-content.component.scss'
})
export class EditContentComponent implements OnInit, AfterViewInit {
  @Input() ContentReport!: any;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() editContentEvent = new EventEmitter<any>();
  CurrentLang: string = ""
  reportContentForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private service: ReportsService,
    private Toaster: ToastService
  ) {
    this.CurrentLang = translate.currentLang;
  }

  ngOnInit(): void {
    this.reportContentForm = this.fb.group({
      title: ['', Validators.required],
      contents: this.fb.array([this.createContent()], Validators.required)
    });
  }

  ngAfterViewInit() {
    this.reportContentForm.patchValue({
      title: this.ContentReport.title
    });

    this.contents.clear();

    // Add form groups based on the contents from ContentReport
    this.ContentReport.contents.forEach((content: string) => {
      content = this.extractText(content)
      const group = this.createContent();
      group.patchValue({content});
      this.contents.push(group);
    });
  }

  get contents(): FormArray {
    return this.reportContentForm.get('contents') as FormArray;
  }

  createContent(): FormGroup {
    return this.fb.group({
      content: ['', Validators.required]
    });
  }

  addContent(): void {
    this.contents.push(this.createContent());
  }

  removeContent(index: number): void {
    this.contents.removeAt(index);
  }

  onSubmit(): void {
    if (this.reportContentForm.valid) {
      let form = this.reportContentForm.value;
      let data = new FormData();
      let strings: any[] = [];
      data.append('title', form.title);


      form.contents.forEach((res: any) => {
        strings.push(res.content);
      });


      data.append('contents', JSON.stringify(strings));


      this.service.update_report_content_description(this.ContentReport.id, data).then((res) => {
        this.Toaster.ShowToastSuccess(this.translate.instant("MSG.CONTENT_UPDATED"), this.translate.instant("MSG.SUCCESS"));
        this.editContentEvent.emit(data);
        this.closeDialog.emit();
      });
    } else {
      this.Toaster.ShowToastError(this.translate.instant("MSG.FILL_THE_FIELDS"), this.translate.instant("MSG.WARNING"));
    }
  }

  onCancel(): void {
    this.closeDialog.emit();
  }


  extractText(input: string): string {

    const cleanedText = input.replace(/[^a-zA-Z0-9\s]/g, '');

    return cleanedText.trim();
  }
}
