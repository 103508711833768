<div class="users-container" [class.arabic]="isArabic">
  <h1 class="t5">{{ 'SIDEBAR.MANAGE_SUBADMINS' | translate }}</h1>

  <div class="controls">
    <div class="left-controls">
      <select class="show-select" [(ngModel)]="tableHeadersize" (change)="onSizeChange($event)">
        <option [value]="5">5</option>
        <option [value]="10">10</option>
        <option [value]="20">20</option>
      </select>
      <input type="text" [placeholder]="'SEARCH_PLACEHOLDER' | translate" class="user-search" [(ngModel)]="name">
      <button class="apply-btn" [class.arabic]="isArabic" (click)="SUB_ADMINS(0)">{{ 'APPLY' | translate }}</button>
    </div>
    <div class="right-controls">
      <select class="sort-select" [(ngModel)]="status">
        <option disabled>{{ 'SELECT' | translate }}</option>
        <option value="false">{{ 'SUSPENDED' | translate }}</option>
        <option value="true">{{ 'ACTIVE' | translate }}</option>
      </select>
    </div>
  </div>

  <table class="users-table">
    <thead>
    <tr>
      <th [class.arabic]="isArabic" *ngFor="let header of tableHeaders">
        {{ header.key | translate }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let subadmin of paginatedSubAdmin.sub_admins">
      <td>{{ subadmin.username }}</td>
      <td>{{ subadmin.email }}</td>
      <td>{{ subadmin.phonenumber }}</td>
      <td>{{ subadmin.timestmp | date }}</td>
      <td>
              <span *ngIf="hasAccess()" class="action-icon" [title]="'PREVILEGES' | translate"
                    (click)="openViewDialog('PREVILEGES',subadmin)">
                <i class="fa-solid fa-key t2 color2"></i>
              </span>
        <span *ngIf="hasAccess()"  class="action-icon" (click)="Activate(subadmin.id)" [title]="'REMOVE' | translate">
                <i class="fa-solid fa-user-xmark t2 color1"></i>
              </span>
      </td>
    </tr>
    <tr *ngIf="paginatedSubAdmin.sub_admins.length === 0">
      <td colspan="7" class="text-center">{{ 'No Data Found' | translate }}</td>
    </tr>
    </tbody>
  </table>
  <div class="paginator">
    <p-paginator *ngIf="paginatedSubAdmin.sub_admins.length > 0" [rows]="paginatedSubAdmin.size"
                 [totalRecords]="paginatedSubAdmin.nb_sub_admins" (onPageChange)="onPageChange($event)"></p-paginator>
  </div>
</div>
