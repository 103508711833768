import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { Group, Member } from '../../../models/user';
import {UserService} from "../../../services/Api/user/user.service";
import {PaginatedUsers, UsersGrouppaginator} from "../../../models/paginators";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'users-group',
  templateUrl: './users-group.component.html',
  styleUrl: './users-group.component.scss'
})
export class UsersGroupComponent implements OnInit{
  @Input() user: any;
  @Output() closeDialog = new EventEmitter<void>();
  CurrentLang: string = "";
  paginator_user_group: UsersGrouppaginator = { size: 5, nb_user_group: 0, page: -1, user_group: [], pages: 1 }

  constructor(private translate: TranslateService,private service:UserService,private spinner: NgxSpinnerService) {
    this.CurrentLang = this.translate.currentLang;
  }


  ngOnInit() {
    if (this.user) {
      this.GetUserGroup(0)
    }
  }



  GetUserGroup(page:any){
    this.spinner.show()
    const size = this.paginator_user_group.size || 5;
    this.service.usergroup(page, size,this.user.id).then((res)=>{
      this.spinner.hide()
      this.paginator_user_group.nb_user_group = res.totalItems
      this.paginator_user_group.page = res.currentPage
      this.paginator_user_group.pages = res.totalPages
      this.paginator_user_group.user_group = res.result
    }).finally(()=>{
      this.spinner.hide()
    })
  }

  onClose() {
    this.closeDialog.emit();
  }

}
