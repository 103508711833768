<div class="users-container" [class.arabic]="isArabic">
  <h1 class="t5">{{ 'SIDEBAR.CONTENT_MANAGEMENT' | translate }}</h1>

  <div class="controls">
    <div class="left-controls">
      <select class="show-select" [(ngModel)]="tableHeadersize" (change)="onSizeChange($event)">
        <option [value]="5">5</option>
        <option [value]="10">10</option>
        <option [value]="20">20</option>
      </select>
      <input type="text" [placeholder]="'SEARCH_PLACEHOLDER' | translate" class="user-search" [(ngModel)]="title">
      <button class="apply-btn" [class.arabic]="isArabic" (click)="GET_CONTENT(0)">{{ 'APPLY' | translate }}</button>
    </div>
  </div>

  <table class="users-table">
    <thead>
    <tr>
      <th [class.arabic]="isArabic" *ngFor="let header of tableHeaders">
        {{ header.key | translate }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let content of PaginatedContentReports.content || []">
      <td>{{ content.id ? content.id : '#' }}</td>
      <td>{{ content.title ? content.title : '-' }}</td>
      <td class="how-much">
        <span>{{ content.contents && content.contents.length > 0 ? extractText(content.contents[0]) : '-' }}</span>
        <div class="arrow-container">
          <span> + {{ content.contents.length - 1 }}</span>
        </div>
      </td>
      <td>
          <span class="action-icon t1 color1" [title]="'VIEW' | translate"
                (click)="openViewDialog('VIEW_CONTENT',content)">
            <i class="fa-solid fa-eye"></i>
          </span>
        <span class="action-icon t1 color9" [title]="'Edit' | translate"
              (click)="openViewDialog('EDIT_CONTENT',content)" *ngIf="hasAccess_settings()">
            <i class="fa-regular fa-pen-to-square"></i>
          </span>
        <span class="action-icon t1 color9" [title]="'Edit' | translate"
              (click)="openViewDialog('ADD_DESCRIPTION_CONTENT',content)" *ngIf="hasAccess_settings()">
            <i class="fa-solid fa-plus"></i>
          </span>
        <span class="action-icon t1 text-danger" [title]="'DELETE' | translate" (click)="Delete(content.id)" *ngIf="hasAccess_settings()">
            <i class="fa-solid fa-trash"></i>
          </span>
      </td>
    </tr>
    <tr *ngIf="!(PaginatedContentReports.content && PaginatedContentReports.content.length)">
      <td colspan="7" class="text-center">{{ 'No Data Found' | translate }}</td>
    </tr>
    </tbody>
  </table>

  <div class="paginator">
    <p-paginator *ngIf="PaginatedContentReports.content && PaginatedContentReports.content.length > 0"
                 [rows]="PaginatedContentReports.size"
                 [totalRecords]="PaginatedContentReports.nb_content"
                 (onPageChange)="onPageChange($event)"></p-paginator>
  </div>
</div>
