import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private isOpenSubject = new BehaviorSubject<boolean>(false);
  private titleSubject = new BehaviorSubject<string>('');
  private contentSubject = new BehaviorSubject<any>(null);

  isOpen$ = this.isOpenSubject.asObservable();
  title$ = this.titleSubject.asObservable();
  content$ = this.contentSubject.asObservable();

  openDialog(action: string, data?: any) {
    switch (action) {
      case 'VIEW_USER':
        this.titleSubject.next('View User');
        this.contentSubject.next(data);
        break;
      case 'VIEW_GROUP_MEMBERS':
        this.titleSubject.next('View Group Members');
        this.contentSubject.next(data);
        break;
      case 'NEW_SUB_ADMIN':
        this.titleSubject.next('New Sub Admin');
        this.contentSubject.next(null);
        break;
      case 'VIEW_GROUPS_USER':
        this.titleSubject.next('View Groups User');
        this.contentSubject.next(data);
        break;
      case 'VIEW_GROUP_LOCATIONS':
        this.titleSubject.next('View Group Locations');
        this.contentSubject.next(data);
        break;
      case 'VIEW_ACTIVITY_DETAILS':
        this.titleSubject.next('View Activity Details');
        this.contentSubject.next(data);
        break;
      case 'PREVILEGES':
        this.titleSubject.next('View Privileges');
        this.contentSubject.next(data);
        break;
      case 'UPDATE_USER':
        this.titleSubject.next('Update User');
        this.contentSubject.next(data);
        break;
      case 'VIEW_GROUP':
        this.titleSubject.next('View Group');
        this.contentSubject.next(data);
        break;
      case 'NEW_CONTENT':
        this.titleSubject.next('New Content');
        this.contentSubject.next(data);
        break;
      case 'VIEW_CONTENT':
        this.titleSubject.next('View Content');
        this.contentSubject.next(data);
        break;
      case 'EDIT_CONTENT':
        this.titleSubject.next('Edit Content');
        this.contentSubject.next(data);
        break;
      case 'ADD_DESCRIPTION_CONTENT':
        this.titleSubject.next('Add Description to Content');
        this.contentSubject.next(data);
        break;
        case 'INTERESTED_MEMBERS':
        this.titleSubject.next('Interested members');
        this.contentSubject.next(data);
        break;
        case 'CHECKED_MEMBERS':
        this.titleSubject.next('Check members');
        this.contentSubject.next(data);
        break;
        case 'Settings_Alert':
        this.titleSubject.next('Alert');
        this.contentSubject.next(data);
        break;
        case 'VIEW_REPORT':
        this.titleSubject.next('View Report');
        this.contentSubject.next(data);
        break;
      default:
        this.titleSubject.next('');
        this.contentSubject.next(null);
    }
    this.isOpenSubject.next(true);
  }

  closeDialog() {
    this.isOpenSubject.next(false);
    this.resetState()
  }

  private resetState() {
    this.titleSubject.next('');
    this.contentSubject.next(null);
  }

  /***Delete user */
  private deleteUserSubject = new Subject<any>();
  deleteUser$ = this.deleteUserSubject.asObservable();

  deleteUser(user: any) {
    this.deleteUserSubject.next(user);
    this.closeDialog();
  }


  /***Update user */
  private updateUserSubject = new Subject<any>();
  updateUser$ = this.updateUserSubject.asObservable();

  updateUser(user: any) {
    this.updateUserSubject.next(user);
    this.closeDialog();
  }


  /*** Delete group */
  private deleteGroupSubject = new Subject<any>();
  deleteGroup$ = this.deleteGroupSubject.asObservable();

  deleteGroup(group: any) {
    this.deleteGroupSubject.next(group);
    this.closeDialog();
  }

  /*** Suspend group */
  private suspendGroupSubject = new Subject<any>();
  suspendGroup$ = this.suspendGroupSubject.asObservable();

  suspendGroup(group: any) {
    this.suspendGroupSubject.next(group);
    this.closeDialog();
  }


  /***Add sub admin */
  private addSubadminSubject = new Subject<any>();
  addSubadmin$ = this.addSubadminSubject.asObservable();

  // Add this new method
  addSubadmin(subadmin: any) {
    this.addSubadminSubject.next(subadmin);
    this.closeDialog();
  }


  /***Add content */
  private addContentSubject = new Subject<any>();
  addContent$ = this.addContentSubject.asObservable();

  // Add this new method
  addContent(subadmin: any) {
    this.addContentSubject.next(subadmin);
    this.closeDialog();
  }


  /***edit content */
  private editContentSubject = new Subject<any>();
  editContent$ = this.editContentSubject.asObservable();

  // Add this new method
  editContent(subadmin: any) {
    this.editContentSubject.next(subadmin);
    this.closeDialog();
  }


  /***add description content */
  private adddescContentSubject = new Subject<any>();
  adddrscContent$ = this.adddescContentSubject.asObservable();

  // Add this new method
  adddescContent(subadmin: any) {
    this.adddescContentSubject.next(subadmin);
    this.closeDialog();
  }


  /***update prev */
  private updateprevSubject = new Subject<any>();
  updateprev$ = this.updateprevSubject.asObservable();

  // Add this new method
  UpdatePrev(subadmin: any) {
    this.updateprevSubject.next(subadmin);
    this.closeDialog();
  }
}
